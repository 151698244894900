import React, {useState} from 'react';
import {Formik} from 'formik';
import {PopOverSidebar} from 'components/organisms/PopOverSidebar';
import Input from 'react-phone-number-input/input';
import {errs, validatePhoneNumber} from 'payble-shared';
import {useCreateContactMutation} from 'lib/graphql/API';
import {useNavigate} from 'react-router-dom';
import {FormErrorMessage} from '../components/FormErrorMessage';
import {FormHeader} from './components/FormHeader';
import {FormSubmission} from './components/FormSubmission';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {ContactWithMobileNumberAlreadyExists} from 'payble-shared/src/errs';
import {useCurrentUser} from '../../../lib/auth';
import {Label, Switch} from 'payble-ui';

const FORM_INITIAL_VALUES = {
  givenName: '',
  familyName: '',
  mobileNumber: '',
  sendIntroduction: false,
};
const VALID_NUMBER_RANGES = ['+614', '+615', '+64'];

type AddContactFormProps = {
  disclosure: ReturnType<typeof useDisclosure>;
};

export const AddContactForm: React.FC<AddContactFormProps> = ({
  disclosure: {isOpen, onClose},
}) => {
  const navigate = useNavigate();
  const [createContact] = useCreateContactMutation();
  const [existingContactId, setExistingContactId] = useState<string>();
  const {billerConfig} = useCurrentUser();
  return (
    <PopOverSidebar
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <Formik
        initialValues={FORM_INITIAL_VALUES}
        validate={values => {
          let errors = {};

          const validatedPhoneNumber = validatePhoneNumber(values.mobileNumber);

          if (
            validatedPhoneNumber !== values.mobileNumber ||
            !VALID_NUMBER_RANGES.some(
              range => !!values?.mobileNumber?.startsWith(range)
            )
          ) {
            errors = {
              ...errors,
              mobileNumber: 'Invalid mobile number',
            };
          }
          if (!values.givenName) {
            errors = {
              ...errors,
              givenName: 'Given name is required.',
            };
          }
          if (!values.familyName) {
            errors = {
              ...errors,
              familyName: 'Last name is required.',
            };
          }

          return errors;
        }}
        onSubmit={async (values, {setSubmitting, setErrors}) => {
          const result = await createContact({
            variables: {
              input: {
                givenName: values.givenName,
                familyName: values.familyName,
                mobileNumber: values.mobileNumber,
                sendIntroduction: values.sendIntroduction,
              },
            },
          });

          if (result?.errors) {
            setSubmitting(false);

            const error = errs
              .fromGraphQL({
                graphQLErrors: result.errors,
              })
              .first();

            if (error instanceof ContactWithMobileNumberAlreadyExists) {
              setExistingContactId(error.context.contactId);
            }

            setErrors({
              mobileNumber: error.message ?? 'Something went wrong.',
            });

            return;
          }

          const contactId = result?.data?.createContact?.id;

          if (contactId) {
            navigate(`/audience/contact/${contactId}`);
          }

          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          touched,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form
            className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl"
            onSubmit={handleSubmit}
          >
            <div className="flex-1">
              <FormHeader
                setOpen={onClose}
                title="Add Contact"
                description="Add a contact to your audience."
              />

              <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label
                      htmlFor="given-name"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                    >
                      Given Name
                    </label>
                  </div>
                  <div className="sm:col-span-2">
                    <input
                      type="text"
                      name="givenName"
                      id="given-name"
                      placeholder="Given Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.givenName}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                    {errors.givenName && touched.givenName && (
                      <FormErrorMessage message={errors.givenName} />
                    )}
                  </div>
                </div>

                <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label
                      htmlFor="family-name"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                    >
                      Last Name
                    </label>
                  </div>
                  <div className="sm:col-span-2">
                    <input
                      type="text"
                      name="familyName"
                      id="family-name"
                      placeholder="Family Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.familyName}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                    {errors.familyName && touched.familyName && (
                      <FormErrorMessage message={errors.familyName} />
                    )}
                  </div>
                </div>

                <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label
                      htmlFor="mobile-number"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                    >
                      Mobile Number
                    </label>
                  </div>
                  <div className="sm:col-span-2">
                    <Input
                      onChange={e => {
                        setFieldValue('mobileNumber', e);
                      }}
                      onBlur={handleBlur}
                      value={values.mobileNumber}
                      defaultCountry={billerConfig.region}
                      type="tel"
                      name="mobileNumber"
                      id="mobile-number"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      placeholder="0400 000 000"
                    />
                    {errors.mobileNumber && touched.mobileNumber && (
                      <FormErrorMessage message={errors.mobileNumber} />
                    )}
                    {errors.mobileNumber &&
                      touched.mobileNumber &&
                      existingContactId && (
                        <div className="mt-1">
                          <a
                            href={`/audience/contact/${existingContactId}`}
                            className="font-semibold text-blue-600 font-sm hover:text-blue-500"
                          >
                            View existing contact.
                          </a>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-2 px-6 mt-2">
                <Switch
                  id="send-welcome-sms"
                  onCheckedChange={checked =>
                    setFieldValue('sendIntroduction', checked)
                  }
                />
                <Label htmlFor="send-welcome-sms">Send welcome SMS</Label>
              </div>
            </div>

            <FormSubmission
              isSubmitting={isSubmitting}
              onCancel={onClose}
              submissionButtonText="Add Contact"
            />
          </form>
        )}
      </Formik>
    </PopOverSidebar>
  );
};
