import {Menu} from '@headlessui/react';
import classNames from 'classnames';
import React from 'react';
import {PencilSquareIcon, ArrowPathIcon} from '@heroicons/react/24/outline';
import {BillerConfig} from 'payble-shared';

type CreatePlansMenuOptionsProps = {
  billerConfig: BillerConfig;
  onOpenInstalmentPlanForm: () => void;
  onOpenEnableAutoPay: () => void;
};

export const CreatePlansMenuOptions: React.FC<CreatePlansMenuOptionsProps> = ({
  billerConfig,
  onOpenInstalmentPlanForm,
  onOpenEnableAutoPay,
}) => {
  return (
    <>
      {billerConfig.adminCreatedPlans.flexibleFixed.enabled && (
        <Menu.Item>
          {({active}) => (
            <button
              id="create-plan"
              onClick={() => onOpenInstalmentPlanForm()}
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                'px-4 py-2 text-sm flex justify-between cursor-pointer w-full'
              )}
            >
              New Payment Plan
              <PencilSquareIcon aria-hidden="true" className="h-5 w-5" />
            </button>
          )}
        </Menu.Item>
      )}

      {billerConfig?.features?.AUTO &&
        billerConfig.adminCreatedPlans.autoPay.enabled && (
          <Menu.Item>
            {({active}) => (
              <button
                id="enable-auto-pay"
                onClick={() => onOpenEnableAutoPay()}
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                  'px-4 py-2 text-sm flex justify-between cursor-pointer w-full'
                )}
              >
                Enable Auto Pay
                <ArrowPathIcon aria-hidden="true" className="h-5 w-5" />
              </button>
            )}
          </Menu.Item>
        )}
    </>
  );
};
