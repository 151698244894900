import { AbsoluteDate } from 'payble-shared';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AbsoluteDate: { input: AbsoluteDate; output: AbsoluteDate; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the 'date-time' format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: Date | string; output: string; }
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec. */
  EmailAddress: { input: string; output: string; }
  /** A field whose value / structure is unknown and does not conform to an interface which can be predetermined. */
  JSON: { input: any; output: any; }
  /** A field whose value conforms to the standard E.164 format as specified in: https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234. */
  PhoneNumber: { input: string; output: string; }
  /** The Void scalar type represents no value being returned */
  Void: { input: void; output: void; }
};

export type Account = {
  __typename?: 'Account';
  amountOwing: Scalars['Int']['output'];
  contacts?: Maybe<Array<Contact>>;
  description: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  meta?: Maybe<Scalars['JSON']['output']>;
  setupBreakdown?: Maybe<Array<InstalmentBreakdown>>;
  standing?: Maybe<AccountStanding>;
  status: AccountStatus;
  targetInstalments: Array<TargetInstalment>;
  type: Scalars['String']['output'];
};

export type AccountConnection = {
  __typename?: 'AccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AccountEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type AccountEdge = {
  __typename?: 'AccountEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<Account>;
};

export type AccountLookupSlice = {
  __typename?: 'AccountLookupSlice';
  accountType: Scalars['String']['output'];
  description: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
};

export type AccountStanding = {
  __typename?: 'AccountStanding';
  instalmentPlanCancelledReason?: Maybe<Scalars['String']['output']>;
  instalmentPlanId?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Owner>;
  paymentId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export enum AccountStatus {
  Active = 'active',
  Archived = 'archived'
}

export type Action = {
  __typename?: 'Action';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  linkTo: Scalars['String']['output'];
  name: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  type: Scalars['String']['output'];
};

export type ActionConnection = {
  __typename?: 'ActionConnection';
  edges: Array<ActionEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type ActionEdge = {
  __typename?: 'ActionEdge';
  cursor: Scalars['String']['output'];
  node: Action;
};

export type AddPaymentMethodInput = {
  bank?: InputMaybe<PaymentMethodBankInput>;
  contactId: Scalars['String']['input'];
  nzBank?: InputMaybe<PaymentMethodNzBankInput>;
  paymentMethodType: PaymentMethodType;
};

export type AddPaymentMethodOutput = {
  __typename?: 'AddPaymentMethodOutput';
  contact: Contact;
  paymentMethodId: Scalars['String']['output'];
};

export type AdminRequestedNotificationInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['String']['input'];
  dryRun: Scalars['Boolean']['input'];
  type: AdminRequestedNotificationInputType;
};

export enum AdminRequestedNotificationInputType {
  CreatePlan = 'createPlan',
  OneOffPayment = 'oneOffPayment',
  UpdatePaymentMethod = 'updatePaymentMethod'
}

export type ArchivePayerInput = {
  deletedReason?: InputMaybe<Scalars['String']['input']>;
  payerId: Scalars['ID']['input'];
};

export type AudienceStats = {
  __typename?: 'AudienceStats';
  activeInstalmentPlans: Scalars['Int']['output'];
  activeInstalmentPlansLastMonth: Scalars['Int']['output'];
  totalAccounts: Scalars['Int']['output'];
  totalAccountsLastMonth: Scalars['Int']['output'];
  totalContacts: Scalars['Int']['output'];
  totalContactsLastMonth: Scalars['Int']['output'];
};

export type AuditLog = {
  __typename?: 'AuditLog';
  action: Scalars['String']['output'];
  adminId: Scalars['String']['output'];
  adminName: Scalars['String']['output'];
  correlationId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  ip?: Maybe<Scalars['String']['output']>;
  isMutation: Scalars['Boolean']['output'];
  isSuccess: Scalars['Boolean']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type AuditLogConnection = {
  __typename?: 'AuditLogConnection';
  edges: Array<AuditLogEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type AuditLogEdge = {
  __typename?: 'AuditLogEdge';
  cursor: Scalars['String']['output'];
  node: AuditLog;
};

export type AuditLogSearchInput = {
  adminId?: InputMaybe<Scalars['String']['input']>;
  dateRange?: InputMaybe<DateRange>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AuditLogType>;
};

export enum AuditLogType {
  Mutation = 'mutation',
  Query = 'query'
}

export type Bank = {
  __typename?: 'Bank';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CancelInstalmentPlanInput = {
  instalmentPlanId: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

/** The different communication channels supported */
export enum Channel {
  Email = 'email',
  Sms = 'sms'
}

export type Contact = {
  __typename?: 'Contact';
  accounts?: Maybe<Array<Account>>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  emailVerified: Scalars['Boolean']['output'];
  familyName: Scalars['String']['output'];
  givenName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mobile: Scalars['String']['output'];
  mobileVerified: Scalars['Boolean']['output'];
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  status: ContactStatus;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ContactConnection = {
  __typename?: 'ContactConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ContactEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type ContactEdge = {
  __typename?: 'ContactEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<Contact>;
};

export enum ContactStatus {
  Active = 'active',
  Archived = 'archived'
}

export type CreateContactInput = {
  familyName: Scalars['String']['input'];
  givenName: Scalars['String']['input'];
  mobileNumber: Scalars['String']['input'];
  sendIntroduction?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateInstalmentPlanInput = {
  accountId: Scalars['String']['input'];
  allowedMissedInstalments?: InputMaybe<Scalars['Int']['input']>;
  canSkip?: InputMaybe<Scalars['Boolean']['input']>;
  contactId?: InputMaybe<Scalars['String']['input']>;
  instalments?: InputMaybe<Array<FixedInstalmentInput>>;
  payMode: InstalmentPlanMode;
  payerId?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId: Scalars['String']['input'];
};

export type CreatePayerInput = {
  accountId: Scalars['String']['input'];
  bank?: InputMaybe<PaymentMethodBankInput>;
  nzBank?: InputMaybe<PaymentMethodNzBankInput>;
  paymentMethodType: PaymentMethodType;
};

export type DateRange = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
};

export type File = {
  __typename?: 'File';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fileFormat: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  fileType: Scalars['String']['output'];
  signedUrl?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  uploadedBy: Scalars['String']['output'];
  uploadedVia: Scalars['String']['output'];
};

export enum FilePrefix {
  Export = 'export',
  Import = 'import',
  Upload = 'upload'
}

export type FixedInstalmentInput = {
  amount: Scalars['Float']['input'];
  dueAt: Scalars['AbsoluteDate']['input'];
};

export type GetSignedUrlInput = {
  fileFormat: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  fileType: Scalars['String']['input'];
};

export type GetSignedUrlOutput = {
  __typename?: 'GetSignedUrlOutput';
  fields: Scalars['JSON']['output'];
  url: Scalars['String']['output'];
};

export type Instalment = {
  __typename?: 'Instalment';
  amount: Scalars['Float']['output'];
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  dueAt: Scalars['AbsoluteDate']['output'];
  instalmentId: Scalars['String']['output'];
  missedAt?: Maybe<Scalars['DateTime']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  payments?: Maybe<Array<Payment>>;
  refundedAt?: Maybe<Scalars['DateTime']['output']>;
  skippedAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<InstalmentStatus>;
};

export type InstalmentBreakdown = {
  __typename?: 'InstalmentBreakdown';
  amount: Scalars['Int']['output'];
  label: Scalars['String']['output'];
};

export enum InstalmentFrequency {
  Annually = 'annually',
  Automatic = 'automatic',
  Fortnightly = 'fortnightly',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Weekly = 'weekly'
}

export type InstalmentPlan = {
  __typename?: 'InstalmentPlan';
  account?: Maybe<Account>;
  accountId: Scalars['ID']['output'];
  amount: Scalars['Float']['output'];
  amountDue: Scalars['Float']['output'];
  amountPaid: Scalars['Float']['output'];
  billerId: Scalars['ID']['output'];
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  cancelledReason: Scalars['String']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  events?: Maybe<Array<InstalmentPlanEvent>>;
  frequency?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instalments: Array<Instalment>;
  mode: InstalmentPlanMode;
  nextInstalmentDueAt?: Maybe<Scalars['AbsoluteDate']['output']>;
  ownerType: InstalmentPlanOwnerType;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodId: Scalars['ID']['output'];
  rebalance: PlanRebalanceStatus;
  status: InstalmentPlanStatus;
  testMode: Scalars['Boolean']['output'];
};

export type InstalmentPlanEvent = {
  __typename?: 'InstalmentPlanEvent';
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['JSON']['output'];
  id: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/**
 * PayEveryX - Kingston flexible pay
 * BalancedPayEveryX - Pay multiple target dates with balanced payments by frequency
 * PayOnY - Scheduled one-off payment
 * PayXOnY - Auto pay, pay amount on specific date(s)
 * PayXEveryZ - Instalments, ongoing to pay X amount every Z days
 * SmoothPay - Plan changes depending on whether the plan has a known amount and due date. On-period (PayXEveryZ), Off-period (PayEveryX)
 * CatchUp - Pay off arrears and then continue with the regular plan has a single target date and frequency
 */
export enum InstalmentPlanMode {
  BalancedPayEveryX = 'BalancedPayEveryX',
  CatchUp = 'CatchUp',
  PayEveryX = 'PayEveryX',
  PayOnY = 'PayOnY',
  PayXEveryZ = 'PayXEveryZ',
  PayXOnY = 'PayXOnY',
  SmoothPay = 'SmoothPay'
}

export enum InstalmentPlanOwnerType {
  Contact = 'CONTACT',
  Payer = 'PAYER'
}

export enum InstalmentPlanStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
  OnTrack = 'onTrack',
  Overdue = 'overdue',
  Pending = 'pending',
  Processing = 'processing'
}

export enum InstalmentStatus {
  Cancelled = 'cancelled',
  Missed = 'missed',
  Overdue = 'overdue',
  Paid = 'paid',
  Processing = 'processing',
  Refunded = 'refunded',
  Scheduled = 'scheduled',
  Skipped = 'skipped'
}

/** MutationType */
export type Mutation = {
  __typename?: 'Mutation';
  addPaymentMethod: AddPaymentMethodOutput;
  archivePayer: Payer;
  cancelInstalmentPlan: InstalmentPlan;
  createContact?: Maybe<Contact>;
  createInstalmentPlan: InstalmentPlan;
  createPayer: Payer;
  getSignedUrl?: Maybe<GetSignedUrlOutput>;
  rebalancePlan: InstalmentPlan;
  reviewPlanRequest: ReviewPlanRequestOutput;
  sendAdminRequestedNotification?: Maybe<SendAdminRequestedNotificationOutput>;
};


/** MutationType */
export type MutationAddPaymentMethodArgs = {
  input: AddPaymentMethodInput;
};


/** MutationType */
export type MutationArchivePayerArgs = {
  input: ArchivePayerInput;
};


/** MutationType */
export type MutationCancelInstalmentPlanArgs = {
  input: CancelInstalmentPlanInput;
};


/** MutationType */
export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


/** MutationType */
export type MutationCreateInstalmentPlanArgs = {
  input: CreateInstalmentPlanInput;
};


/** MutationType */
export type MutationCreatePayerArgs = {
  input: CreatePayerInput;
};


/** MutationType */
export type MutationGetSignedUrlArgs = {
  input: GetSignedUrlInput;
};


/** MutationType */
export type MutationRebalancePlanArgs = {
  input: RebalancePlanInput;
};


/** MutationType */
export type MutationReviewPlanRequestArgs = {
  input: ReviewPlanRequestInput;
};


/** MutationType */
export type MutationSendAdminRequestedNotificationArgs = {
  input: AdminRequestedNotificationInput;
};

export type Notification = {
  __typename?: 'Notification';
  channel: NotificationChannel;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  preview: Scalars['String']['output'];
  sentAt: Scalars['DateTime']['output'];
  status: NotificationStatus;
};

export enum NotificationChannel {
  Email = 'email',
  Sms = 'sms'
}

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  edges: Array<NotificationEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  cursor: Scalars['String']['output'];
  node: Notification;
};

export enum NotificationStatus {
  Delivered = 'delivered',
  Failed = 'failed',
  Undelivered = 'undelivered'
}

export type Owner = {
  __typename?: 'Owner';
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Payer = {
  __typename?: 'Payer';
  account: Account;
  accountId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  meta?: Maybe<Scalars['JSON']['output']>;
  payerId?: Maybe<Scalars['String']['output']>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PayerConnection = {
  __typename?: 'PayerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PayerEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type PayerEdge = {
  __typename?: 'PayerEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<Payer>;
};

/** A payment */
export type Payment = {
  __typename?: 'Payment';
  account?: Maybe<Account>;
  accountId: Scalars['String']['output'];
  amount: Scalars['Float']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency: Scalars['String']['output'];
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  failedReason?: Maybe<Scalars['String']['output']>;
  gateway: Scalars['String']['output'];
  gatewayTransactionId?: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  instalmentId?: Maybe<Scalars['String']['output']>;
  instalmentPlanId?: Maybe<Scalars['String']['output']>;
  mode: PaymentMode;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodId: Scalars['String']['output'];
  processedAt?: Maybe<Scalars['DateTime']['output']>;
  refundStatus?: Maybe<PaymentRefundStatus>;
  refundedAt?: Maybe<Scalars['DateTime']['output']>;
  status: PaymentStatus;
  succeededAt?: Maybe<Scalars['DateTime']['output']>;
  testMode: Scalars['Boolean']['output'];
};

export type PaymentConnection = {
  __typename?: 'PaymentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PaymentEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type PaymentEdge = {
  __typename?: 'PaymentEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<Payment>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  bank?: Maybe<PaymentMethodBank>;
  card?: Maybe<PaymentMethodCard>;
  id: Scalars['String']['output'];
  nzBank?: Maybe<PaymentMethodNzBank>;
  reason?: Maybe<Scalars['String']['output']>;
  status: PaymentMethodStatus;
  type: PaymentMethodType;
};

export type PaymentMethodBank = {
  __typename?: 'PaymentMethodBank';
  accountName: Scalars['String']['output'];
  accountNumber: Scalars['String']['output'];
  bsb: Scalars['String']['output'];
  gateway: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type PaymentMethodBankInput = {
  accountName: Scalars['String']['input'];
  accountNumber: Scalars['String']['input'];
  bsb: Scalars['String']['input'];
};

export type PaymentMethodCard = {
  __typename?: 'PaymentMethodCard';
  brand: Scalars['String']['output'];
  expiryMonth: Scalars['Int']['output'];
  expiryYear: Scalars['Int']['output'];
  gateway: Scalars['String']['output'];
  holderName: Scalars['String']['output'];
  last4: Scalars['String']['output'];
};

export type PaymentMethodNzBank = {
  __typename?: 'PaymentMethodNZBank';
  accountName: Scalars['String']['output'];
  accountNumber: Scalars['String']['output'];
  gateway: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type PaymentMethodNzBankInput = {
  accountName: Scalars['String']['input'];
  accountNumber: Scalars['String']['input'];
};

export enum PaymentMethodStatus {
  Active = 'active',
  Disabled = 'disabled',
  Removed = 'removed'
}

export enum PaymentMethodType {
  Card = 'card',
  DirectDebit = 'directDebit',
  NzDirectDebit = 'nzDirectDebit'
}

export enum PaymentMode {
  PayAmount = 'payAmount',
  PayInFull = 'payInFull',
  PayInstalment = 'payInstalment',
  PayInstalmentPlanRemainder = 'payInstalmentPlanRemainder'
}

export enum PaymentRefundStatus {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Processing = 'processing',
  Succeeded = 'succeeded'
}

export type PaymentStats = {
  __typename?: 'PaymentStats';
  totalFailed: Scalars['Int']['output'];
  totalFailedLastMonth: Scalars['Int']['output'];
  totalScheduled: Scalars['Int']['output'];
  totalScheduledLastMonth: Scalars['Int']['output'];
  totalSuccessful: Scalars['Int']['output'];
  totalSuccessfulLastMonth: Scalars['Int']['output'];
};

export enum PaymentStatus {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Processing = 'processing',
  Succeeded = 'succeeded'
}

export enum PlanRebalanceOutcome {
  NotPossible = 'notPossible',
  Possible = 'possible',
  PossibleWithWarning = 'possibleWithWarning'
}

export type PlanRebalanceStatus = {
  __typename?: 'PlanRebalanceStatus';
  cause?: Maybe<Scalars['String']['output']>;
  outcome: PlanRebalanceOutcome;
};

export type PlanRequest = {
  __typename?: 'PlanRequest';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  accountId: Scalars['String']['output'];
  billerId: Scalars['String']['output'];
  contactId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  frequency: InstalmentFrequency;
  id: Scalars['ID']['output'];
  instalmentAmount: Scalars['Int']['output'];
  paymentMethodId: Scalars['String']['output'];
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  startAt: Scalars['AbsoluteDate']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PlanRequestConnection = {
  __typename?: 'PlanRequestConnection';
  edges: Array<PlanRequestEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type PlanRequestEdge = {
  __typename?: 'PlanRequestEdge';
  cursor: Scalars['String']['output'];
  node: PlanRequest;
};

/** QueryType */
export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  accountLookup: Array<AccountLookupSlice>;
  accountTypes?: Maybe<Array<Scalars['String']['output']>>;
  accounts: AccountConnection;
  actions: ActionConnection;
  audienceStats: AudienceStats;
  auditLogs: AuditLogConnection;
  /** Get a bank by bsb code */
  bankByCode?: Maybe<Bank>;
  contact?: Maybe<Contact>;
  contacts: ContactConnection;
  files?: Maybe<Array<File>>;
  /** Get the current instalment plans for this user */
  instalmentPlan?: Maybe<InstalmentPlan>;
  instalmentPlans?: Maybe<Array<InstalmentPlan>>;
  notifications: NotificationConnection;
  payer?: Maybe<Payer>;
  payers: PayerConnection;
  paymentStats: PaymentStats;
  payments: PaymentConnection;
  planRequests: PlanRequestConnection;
  scheduledPayments: ScheduledPaymentConnection;
  session: Session;
};


/** QueryType */
export type QueryAccountArgs = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


/** QueryType */
export type QueryAccountLookupArgs = {
  lookup: Scalars['String']['input'];
};


/** QueryType */
export type QueryAccountsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** QueryType */
export type QueryActionsArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


/** QueryType */
export type QueryAuditLogsArgs = {
  input: AuditLogSearchInput;
};


/** QueryType */
export type QueryBankByCodeArgs = {
  code: Scalars['String']['input'];
  region: Scalars['String']['input'];
};


/** QueryType */
export type QueryContactArgs = {
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  mobile?: InputMaybe<Scalars['PhoneNumber']['input']>;
};


/** QueryType */
export type QueryContactsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


/** QueryType */
export type QueryFilesArgs = {
  prefix: FilePrefix;
};


/** QueryType */
export type QueryInstalmentPlanArgs = {
  id: Scalars['ID']['input'];
};


/** QueryType */
export type QueryInstalmentPlansArgs = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  contactId?: InputMaybe<Scalars['String']['input']>;
};


/** QueryType */
export type QueryNotificationsArgs = {
  contactId: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


/** QueryType */
export type QueryPayerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


/** QueryType */
export type QueryPayersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


/** QueryType */
export type QueryPaymentsArgs = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  contactId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mode?: InputMaybe<Array<PaymentMode>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  payerId?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


/** QueryType */
export type QueryPlanRequestsArgs = {
  contactId?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


/** QueryType */
export type QueryScheduledPaymentsArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type RebalancePlanInput = {
  dryRun: Scalars['Boolean']['input'];
  instalmentPlanId: Scalars['String']['input'];
};

export enum RequestOutcome {
  Accepted = 'accepted',
  Rejected = 'rejected'
}

/** The status of a request */
export enum RequestStatus {
  Approved = 'approved',
  Cancelled = 'cancelled',
  Pending = 'pending',
  Rejected = 'rejected'
}

export type ReviewPlanRequestInput = {
  allowedMissedInstalments?: InputMaybe<Scalars['Int']['input']>;
  canSkip?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  instalments?: InputMaybe<Array<FixedInstalmentInput>>;
  outcome: RequestOutcome;
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type ReviewPlanRequestOutput = {
  __typename?: 'ReviewPlanRequestOutput';
  instalmentPlan?: Maybe<InstalmentPlan>;
  planRequest: PlanRequest;
};

export type ScheduledPayment = {
  __typename?: 'ScheduledPayment';
  account?: Maybe<Account>;
  accountId: Scalars['String']['output'];
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  dueAt: Scalars['AbsoluteDate']['output'];
  /** The ID of an object */
  id: Scalars['ID']['output'];
  instalmentId: Scalars['String']['output'];
  instalmentPlanId: Scalars['String']['output'];
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodId: Scalars['String']['output'];
};

export type ScheduledPaymentConnection = {
  __typename?: 'ScheduledPaymentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ScheduledPaymentEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type ScheduledPaymentEdge = {
  __typename?: 'ScheduledPaymentEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ScheduledPayment>;
};

export type SendAdminRequestedNotificationOutput = {
  __typename?: 'SendAdminRequestedNotificationOutput';
  body?: Maybe<Array<Scalars['String']['output']>>;
  dryRun: Scalars['Boolean']['output'];
};

/** The current signed in admin with biller info */
export type Session = {
  __typename?: 'Session';
  adminId: Scalars['String']['output'];
  billerId: Scalars['String']['output'];
  billerName: Scalars['String']['output'];
  billerSlug: Scalars['String']['output'];
  email: Scalars['String']['output'];
  expiresAt: Scalars['DateTime']['output'];
};

export type TargetInstalment = {
  __typename?: 'TargetInstalment';
  amount: Scalars['Float']['output'];
  dueAt: Scalars['AbsoluteDate']['output'];
};

export type GetActionsBySearchQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetActionsBySearchQuery = { __typename?: 'Query', actions: { __typename?: 'ActionConnection', total: number, edges: Array<{ __typename?: 'ActionEdge', cursor: string, node: { __typename?: 'Action', id: string, type: string, linkTo: string, name: string, description?: string | null, timestamp: string } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };

export type AddPaymentMethodMutationVariables = Exact<{
  input: AddPaymentMethodInput;
}>;


export type AddPaymentMethodMutation = { __typename?: 'Mutation', addPaymentMethod: { __typename?: 'AddPaymentMethodOutput', paymentMethodId: string, contact: { __typename?: 'Contact', id: string, givenName: string, familyName: string, title: string, email: string, emailVerified: boolean, mobile: string, mobileVerified: boolean, paymentMethods?: Array<{ __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, reason?: string | null, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountName: string, accountNumber: string, gateway: string, name?: string | null } | null }> | null, accounts?: Array<{ __typename?: 'Account', id: string, type: string, externalId: string, description: string, amountOwing: number, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null } | null }> | null } } };

export type ArchivePayerMutationVariables = Exact<{
  input: ArchivePayerInput;
}>;


export type ArchivePayerMutation = { __typename?: 'Mutation', archivePayer: { __typename?: 'Payer', id: string, payerId?: string | null, meta?: any | null, updatedAt: string, createdAt: string, deletedAt?: string | null, deletedReason?: string | null, account: { __typename?: 'Account', id: string, status: AccountStatus, externalId: string, description: string, amountOwing: number, type: string, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null, instalmentPlanCancelledReason?: string | null } | null, setupBreakdown?: Array<{ __typename?: 'InstalmentBreakdown', amount: number, label: string }> | null, targetInstalments: Array<{ __typename?: 'TargetInstalment', amount: number, dueAt: AbsoluteDate }> }, paymentMethods?: Array<{ __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountName: string, accountNumber: string, gateway: string, name?: string | null } | null }> | null } };

export type CancelInstalmentPlanMutationVariables = Exact<{
  input: CancelInstalmentPlanInput;
}>;


export type CancelInstalmentPlanMutation = { __typename?: 'Mutation', cancelInstalmentPlan: { __typename?: 'InstalmentPlan', id: string, mode: InstalmentPlanMode, frequency?: string | null, currency: string, amount: number, amountPaid: number, amountDue: number, nextInstalmentDueAt?: AbsoluteDate | null, accountId: string, billerId: string, paymentMethodId: string, testMode: boolean, status: InstalmentPlanStatus, cancelledReason: string, createdAt: string, cancelledAt?: string | null, completedAt?: string | null, instalments: Array<{ __typename?: 'Instalment', instalmentId: string, status?: InstalmentStatus | null, skippedAt?: string | null, cancelledAt?: string | null, paidAt?: string | null, missedAt?: string | null, amount: number, dueAt: AbsoluteDate }>, account?: { __typename?: 'Account', type: string, externalId: string, description: string } | null, rebalance: { __typename?: 'PlanRebalanceStatus', outcome: PlanRebalanceOutcome, cause?: string | null }, paymentMethod?: { __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountNumber: string, accountName: string, gateway: string, name?: string | null } | null } | null } };

export type CreateContactMutationVariables = Exact<{
  input: CreateContactInput;
}>;


export type CreateContactMutation = { __typename?: 'Mutation', createContact?: { __typename?: 'Contact', id: string, givenName: string, familyName: string, title: string, email: string, emailVerified: boolean, mobile: string, mobileVerified: boolean, paymentMethods?: Array<{ __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, reason?: string | null, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountName: string, accountNumber: string, gateway: string, name?: string | null } | null }> | null, accounts?: Array<{ __typename?: 'Account', id: string, type: string, externalId: string, description: string, amountOwing: number, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null } | null }> | null } | null };

export type CreateInstalmentPlanMutationVariables = Exact<{
  input: CreateInstalmentPlanInput;
}>;


export type CreateInstalmentPlanMutation = { __typename?: 'Mutation', createInstalmentPlan: { __typename?: 'InstalmentPlan', id: string, mode: InstalmentPlanMode, currency: string, amount: number, amountPaid: number, amountDue: number, nextInstalmentDueAt?: AbsoluteDate | null, accountId: string, billerId: string, frequency?: string | null, testMode: boolean, instalments: Array<{ __typename?: 'Instalment', instalmentId: string, amount: number, dueAt: AbsoluteDate, status?: InstalmentStatus | null }> } };

export type CreatePayerMutationVariables = Exact<{
  input: CreatePayerInput;
}>;


export type CreatePayerMutation = { __typename?: 'Mutation', createPayer: { __typename?: 'Payer', id: string, payerId?: string | null, meta?: any | null, updatedAt: string, createdAt: string, deletedAt?: string | null, deletedReason?: string | null, account: { __typename?: 'Account', id: string, status: AccountStatus, externalId: string, description: string, amountOwing: number, type: string, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null, instalmentPlanCancelledReason?: string | null } | null, setupBreakdown?: Array<{ __typename?: 'InstalmentBreakdown', amount: number, label: string }> | null, targetInstalments: Array<{ __typename?: 'TargetInstalment', amount: number, dueAt: AbsoluteDate }> }, paymentMethods?: Array<{ __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountName: string, accountNumber: string, gateway: string, name?: string | null } | null }> | null } };

export type AccountFragment = { __typename?: 'Account', id: string, amountOwing: number, description: string, externalId: string, meta?: any | null, status: AccountStatus, type: string, setupBreakdown?: Array<{ __typename?: 'InstalmentBreakdown', amount: number, label: string }> | null, targetInstalments: Array<{ __typename?: 'TargetInstalment', amount: number, dueAt: AbsoluteDate }>, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null, instalmentPlanCancelledReason?: string | null } | null, contacts?: Array<{ __typename?: 'Contact', id: string, givenName: string, familyName: string, title: string, email: string, emailVerified: boolean, mobile: string, mobileVerified: boolean }> | null };

export type GetAccountByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetAccountByIdQuery = { __typename?: 'Query', account?: { __typename?: 'Account', id: string, amountOwing: number, description: string, externalId: string, meta?: any | null, status: AccountStatus, type: string, setupBreakdown?: Array<{ __typename?: 'InstalmentBreakdown', amount: number, label: string }> | null, targetInstalments: Array<{ __typename?: 'TargetInstalment', amount: number, dueAt: AbsoluteDate }>, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null, instalmentPlanCancelledReason?: string | null } | null, contacts?: Array<{ __typename?: 'Contact', id: string, givenName: string, familyName: string, title: string, email: string, emailVerified: boolean, mobile: string, mobileVerified: boolean }> | null } | null };

export type GetAccountByExternalIdQueryVariables = Exact<{
  externalId: Scalars['String']['input'];
  type: Scalars['String']['input'];
}>;


export type GetAccountByExternalIdQuery = { __typename?: 'Query', account?: { __typename?: 'Account', id: string, amountOwing: number, description: string, externalId: string, meta?: any | null, status: AccountStatus, type: string, setupBreakdown?: Array<{ __typename?: 'InstalmentBreakdown', amount: number, label: string }> | null, targetInstalments: Array<{ __typename?: 'TargetInstalment', amount: number, dueAt: AbsoluteDate }>, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null, instalmentPlanCancelledReason?: string | null } | null, contacts?: Array<{ __typename?: 'Contact', id: string, givenName: string, familyName: string, title: string, email: string, emailVerified: boolean, mobile: string, mobileVerified: boolean }> | null } | null };

export type GetAccountQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAccountQuery = { __typename?: 'Query', account?: { __typename?: 'Account', id: string, amountOwing: number, description: string, externalId: string, meta?: any | null, status: AccountStatus, type: string, setupBreakdown?: Array<{ __typename?: 'InstalmentBreakdown', amount: number, label: string }> | null, targetInstalments: Array<{ __typename?: 'TargetInstalment', amount: number, dueAt: AbsoluteDate }>, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null, instalmentPlanCancelledReason?: string | null } | null, contacts?: Array<{ __typename?: 'Contact', id: string, givenName: string, familyName: string, title: string, email: string, emailVerified: boolean, mobile: string, mobileVerified: boolean }> | null } | null };

export type GetAccountTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountTypeQuery = { __typename?: 'Query', accountTypes?: Array<string> | null };

export type GetAccountsAllQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountsAllQuery = { __typename?: 'Query', accounts: { __typename?: 'AccountConnection', edges?: Array<{ __typename?: 'AccountEdge', node?: { __typename?: 'Account', id: string, amountOwing: number, description: string, externalId: string, meta?: any | null, status: AccountStatus, type: string, setupBreakdown?: Array<{ __typename?: 'InstalmentBreakdown', amount: number, label: string }> | null, targetInstalments: Array<{ __typename?: 'TargetInstalment', amount: number, dueAt: AbsoluteDate }>, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null, instalmentPlanCancelledReason?: string | null } | null, contacts?: Array<{ __typename?: 'Contact', id: string, givenName: string, familyName: string, title: string, email: string, emailVerified: boolean, mobile: string, mobileVerified: boolean }> | null } | null } | null> | null } };

export type GetAccountsSearchQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  types?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type GetAccountsSearchQuery = { __typename?: 'Query', accounts: { __typename?: 'AccountConnection', total: number, edges?: Array<{ __typename?: 'AccountEdge', cursor: string, node?: { __typename?: 'Account', id: string, amountOwing: number, description: string, externalId: string, meta?: any | null, status: AccountStatus, type: string, setupBreakdown?: Array<{ __typename?: 'InstalmentBreakdown', amount: number, label: string }> | null, targetInstalments: Array<{ __typename?: 'TargetInstalment', amount: number, dueAt: AbsoluteDate }>, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null, instalmentPlanCancelledReason?: string | null } | null, contacts?: Array<{ __typename?: 'Contact', id: string, givenName: string, familyName: string, title: string, email: string, emailVerified: boolean, mobile: string, mobileVerified: boolean }> | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };

export type GetAudienceStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAudienceStatsQuery = { __typename?: 'Query', audienceStats: { __typename?: 'AudienceStats', totalAccounts: number, totalAccountsLastMonth: number, totalContacts: number, totalContactsLastMonth: number, activeInstalmentPlans: number, activeInstalmentPlansLastMonth: number } };

export type ContactFragment = { __typename?: 'Contact', id: string, givenName: string, familyName: string, title: string, email: string, emailVerified: boolean, mobile: string, mobileVerified: boolean, paymentMethods?: Array<{ __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, reason?: string | null, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountName: string, accountNumber: string, gateway: string, name?: string | null } | null }> | null, accounts?: Array<{ __typename?: 'Account', id: string, type: string, externalId: string, description: string, amountOwing: number, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null } | null }> | null };

export type GetContactByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetContactByIdQuery = { __typename?: 'Query', contact?: { __typename?: 'Contact', id: string, givenName: string, familyName: string, title: string, email: string, emailVerified: boolean, mobile: string, mobileVerified: boolean, paymentMethods?: Array<{ __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, reason?: string | null, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountName: string, accountNumber: string, gateway: string, name?: string | null } | null }> | null, accounts?: Array<{ __typename?: 'Account', id: string, type: string, externalId: string, description: string, amountOwing: number, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null } | null }> | null } | null };

export type GetContactByMobileQueryVariables = Exact<{
  mobile: Scalars['PhoneNumber']['input'];
}>;


export type GetContactByMobileQuery = { __typename?: 'Query', contact?: { __typename?: 'Contact', id: string, givenName: string, familyName: string, title: string, email: string, emailVerified: boolean, mobile: string, mobileVerified: boolean, paymentMethods?: Array<{ __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, reason?: string | null, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountName: string, accountNumber: string, gateway: string, name?: string | null } | null }> | null, accounts?: Array<{ __typename?: 'Account', id: string, type: string, externalId: string, description: string, amountOwing: number, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null } | null }> | null } | null };

export type GetContactByEmailQueryVariables = Exact<{
  email: Scalars['EmailAddress']['input'];
}>;


export type GetContactByEmailQuery = { __typename?: 'Query', contact?: { __typename?: 'Contact', id: string, givenName: string, familyName: string, title: string, email: string, emailVerified: boolean, mobile: string, mobileVerified: boolean, paymentMethods?: Array<{ __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, reason?: string | null, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountName: string, accountNumber: string, gateway: string, name?: string | null } | null }> | null, accounts?: Array<{ __typename?: 'Account', id: string, type: string, externalId: string, description: string, amountOwing: number, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null } | null }> | null } | null };

export type GetContactByExternalIdQueryVariables = Exact<{
  externalId: Scalars['String']['input'];
}>;


export type GetContactByExternalIdQuery = { __typename?: 'Query', contact?: { __typename?: 'Contact', id: string, givenName: string, familyName: string, title: string, email: string, emailVerified: boolean, mobile: string, mobileVerified: boolean, paymentMethods?: Array<{ __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, reason?: string | null, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountName: string, accountNumber: string, gateway: string, name?: string | null } | null }> | null, accounts?: Array<{ __typename?: 'Account', id: string, type: string, externalId: string, description: string, amountOwing: number, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null } | null }> | null } | null };

export type GetContactQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['PhoneNumber']['input']>;
}>;


export type GetContactQuery = { __typename?: 'Query', contact?: { __typename?: 'Contact', id: string, givenName: string, familyName: string, title: string, email: string, emailVerified: boolean, mobile: string, mobileVerified: boolean, paymentMethods?: Array<{ __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, reason?: string | null, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountName: string, accountNumber: string, gateway: string, name?: string | null } | null }> | null, accounts?: Array<{ __typename?: 'Account', id: string, type: string, externalId: string, description: string, amountOwing: number, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null } | null }> | null } | null };

export type GetFilesQueryVariables = Exact<{
  prefix: FilePrefix;
}>;


export type GetFilesQuery = { __typename?: 'Query', files?: Array<{ __typename?: 'File', fileFormat: string, fileType: string, fileName: string, status: string, createdAt?: string | null, uploadedVia: string, uploadedBy: string, signedUrl?: string | null }> | null };

export type InstalmentPlanDetailFragment = { __typename?: 'InstalmentPlan', id: string, mode: InstalmentPlanMode, frequency?: string | null, currency: string, amount: number, amountPaid: number, amountDue: number, nextInstalmentDueAt?: AbsoluteDate | null, accountId: string, billerId: string, paymentMethodId: string, testMode: boolean, status: InstalmentPlanStatus, cancelledReason: string, createdAt: string, cancelledAt?: string | null, completedAt?: string | null, instalments: Array<{ __typename?: 'Instalment', instalmentId: string, status?: InstalmentStatus | null, skippedAt?: string | null, cancelledAt?: string | null, paidAt?: string | null, missedAt?: string | null, amount: number, dueAt: AbsoluteDate }>, account?: { __typename?: 'Account', type: string, externalId: string, description: string } | null, rebalance: { __typename?: 'PlanRebalanceStatus', outcome: PlanRebalanceOutcome, cause?: string | null }, paymentMethod?: { __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountNumber: string, accountName: string, gateway: string, name?: string | null } | null } | null };

export type GetInstalmentPlansQueryVariables = Exact<{
  accountId?: InputMaybe<Scalars['String']['input']>;
  contactId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetInstalmentPlansQuery = { __typename?: 'Query', instalmentPlans?: Array<{ __typename?: 'InstalmentPlan', id: string, mode: InstalmentPlanMode, frequency?: string | null, currency: string, amount: number, amountPaid: number, amountDue: number, nextInstalmentDueAt?: AbsoluteDate | null, accountId: string, billerId: string, paymentMethodId: string, testMode: boolean, status: InstalmentPlanStatus, cancelledReason: string, createdAt: string, cancelledAt?: string | null, completedAt?: string | null, instalments: Array<{ __typename?: 'Instalment', instalmentId: string, status?: InstalmentStatus | null, skippedAt?: string | null, cancelledAt?: string | null, paidAt?: string | null, missedAt?: string | null, amount: number, dueAt: AbsoluteDate }>, account?: { __typename?: 'Account', type: string, externalId: string, description: string } | null, rebalance: { __typename?: 'PlanRebalanceStatus', outcome: PlanRebalanceOutcome, cause?: string | null }, paymentMethod?: { __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountNumber: string, accountName: string, gateway: string, name?: string | null } | null } | null }> | null };

export type GetInstalmentPlanEventsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetInstalmentPlanEventsQuery = { __typename?: 'Query', instalmentPlan?: { __typename?: 'InstalmentPlan', events?: Array<{ __typename?: 'InstalmentPlanEvent', id: string, type: string, data: any, createdAt: string }> | null } | null };

export type GetPaymentsQueryVariables = Exact<{
  accountId?: InputMaybe<Scalars['String']['input']>;
  contactId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetPaymentsQuery = { __typename?: 'Query', payments: { __typename?: 'PaymentConnection', total: number, edges?: Array<{ __typename?: 'PaymentEdge', cursor: string, node?: { __typename?: 'Payment', id: string, mode: PaymentMode, currency: string, amount: number, accountId: string, instalmentId?: string | null, instalmentPlanId?: string | null, testMode: boolean, status: PaymentStatus, failedReason?: string | null, refundStatus?: PaymentRefundStatus | null, gateway: string, gatewayTransactionId?: string | null, processedAt?: string | null, succeededAt?: string | null, failedAt?: string | null, refundedAt?: string | null, paymentMethod?: { __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountNumber: string, accountName: string, gateway: string, name?: string | null } | null } | null, account?: { __typename?: 'Account', id: string, type: string, externalId: string, description: string, amountOwing: number } | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };

export type GetSignedUrlMutationVariables = Exact<{
  input: GetSignedUrlInput;
}>;


export type GetSignedUrlMutation = { __typename?: 'Mutation', getSignedUrl?: { __typename?: 'GetSignedUrlOutput', fields: any, url: string } | null };

export type LookupAccountsQueryVariables = Exact<{
  lookup: Scalars['String']['input'];
}>;


export type LookupAccountsQuery = { __typename?: 'Query', accountLookup: Array<{ __typename?: 'AccountLookupSlice', description: string, externalId: string, accountType: string }> };

export type GetPayersAllQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPayersAllQuery = { __typename?: 'Query', payers: { __typename?: 'PayerConnection', edges?: Array<{ __typename?: 'PayerEdge', node?: { __typename?: 'Payer', id: string, payerId?: string | null, meta?: any | null, updatedAt: string, createdAt: string, deletedAt?: string | null, deletedReason?: string | null, account: { __typename?: 'Account', id: string, status: AccountStatus, externalId: string, description: string, amountOwing: number, type: string, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null, instalmentPlanCancelledReason?: string | null } | null, setupBreakdown?: Array<{ __typename?: 'InstalmentBreakdown', amount: number, label: string }> | null, targetInstalments: Array<{ __typename?: 'TargetInstalment', amount: number, dueAt: AbsoluteDate }> }, paymentMethods?: Array<{ __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountName: string, accountNumber: string, gateway: string, name?: string | null } | null }> | null } | null } | null> | null } };

export type PayerFragment = { __typename?: 'Payer', id: string, payerId?: string | null, meta?: any | null, updatedAt: string, createdAt: string, deletedAt?: string | null, deletedReason?: string | null, account: { __typename?: 'Account', id: string, status: AccountStatus, externalId: string, description: string, amountOwing: number, type: string, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null, instalmentPlanCancelledReason?: string | null } | null, setupBreakdown?: Array<{ __typename?: 'InstalmentBreakdown', amount: number, label: string }> | null, targetInstalments: Array<{ __typename?: 'TargetInstalment', amount: number, dueAt: AbsoluteDate }> }, paymentMethods?: Array<{ __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountName: string, accountNumber: string, gateway: string, name?: string | null } | null }> | null };

export type GetPayerByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetPayerByIdQuery = { __typename?: 'Query', payer?: { __typename?: 'Payer', id: string, payerId?: string | null, meta?: any | null, updatedAt: string, createdAt: string, deletedAt?: string | null, deletedReason?: string | null, account: { __typename?: 'Account', id: string, status: AccountStatus, externalId: string, description: string, amountOwing: number, type: string, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null, instalmentPlanCancelledReason?: string | null } | null, setupBreakdown?: Array<{ __typename?: 'InstalmentBreakdown', amount: number, label: string }> | null, targetInstalments: Array<{ __typename?: 'TargetInstalment', amount: number, dueAt: AbsoluteDate }> }, paymentMethods?: Array<{ __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountName: string, accountNumber: string, gateway: string, name?: string | null } | null }> | null } | null };

export type GetPayerQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetPayerQuery = { __typename?: 'Query', payer?: { __typename?: 'Payer', id: string, payerId?: string | null, meta?: any | null, updatedAt: string, createdAt: string, deletedAt?: string | null, deletedReason?: string | null, account: { __typename?: 'Account', id: string, status: AccountStatus, externalId: string, description: string, amountOwing: number, type: string, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null, instalmentPlanCancelledReason?: string | null } | null, setupBreakdown?: Array<{ __typename?: 'InstalmentBreakdown', amount: number, label: string }> | null, targetInstalments: Array<{ __typename?: 'TargetInstalment', amount: number, dueAt: AbsoluteDate }> }, paymentMethods?: Array<{ __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountName: string, accountNumber: string, gateway: string, name?: string | null } | null }> | null } | null };

export type GetPayersSearchQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetPayersSearchQuery = { __typename?: 'Query', payers: { __typename?: 'PayerConnection', total: number, edges?: Array<{ __typename?: 'PayerEdge', cursor: string, node?: { __typename?: 'Payer', id: string, payerId?: string | null, meta?: any | null, updatedAt: string, createdAt: string, deletedAt?: string | null, deletedReason?: string | null, account: { __typename?: 'Account', id: string, status: AccountStatus, externalId: string, description: string, amountOwing: number, type: string, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null, instalmentPlanCancelledReason?: string | null } | null, setupBreakdown?: Array<{ __typename?: 'InstalmentBreakdown', amount: number, label: string }> | null, targetInstalments: Array<{ __typename?: 'TargetInstalment', amount: number, dueAt: AbsoluteDate }> }, paymentMethods?: Array<{ __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountName: string, accountNumber: string, gateway: string, name?: string | null } | null }> | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };

export type RebalancePlanMutationVariables = Exact<{
  input: RebalancePlanInput;
}>;


export type RebalancePlanMutation = { __typename?: 'Mutation', rebalancePlan: { __typename?: 'InstalmentPlan', amount: number, amountPaid: number, amountDue: number, instalments: Array<{ __typename?: 'Instalment', instalmentId: string, dueAt: AbsoluteDate, status?: InstalmentStatus | null, amount: number }> } };

export type ReviewPlanRequestMutationVariables = Exact<{
  input: ReviewPlanRequestInput;
}>;


export type ReviewPlanRequestMutation = { __typename?: 'Mutation', reviewPlanRequest: { __typename?: 'ReviewPlanRequestOutput', planRequest: { __typename?: 'PlanRequest', id: string, billerId: string, accountId: string, paymentMethodId: string, frequency: InstalmentFrequency, startAt: AbsoluteDate, instalmentAmount: number, contactId?: string | null, createdAt: string, updatedAt: string, rejectedAt?: string | null, acceptedAt?: string | null }, instalmentPlan?: { __typename?: 'InstalmentPlan', id: string, mode: InstalmentPlanMode, frequency?: string | null, currency: string, amount: number, amountPaid: number, amountDue: number, nextInstalmentDueAt?: AbsoluteDate | null, accountId: string, billerId: string, paymentMethodId: string, testMode: boolean, status: InstalmentPlanStatus, cancelledReason: string, createdAt: string, cancelledAt?: string | null, completedAt?: string | null, instalments: Array<{ __typename?: 'Instalment', instalmentId: string, status?: InstalmentStatus | null, skippedAt?: string | null, cancelledAt?: string | null, paidAt?: string | null, missedAt?: string | null, amount: number, dueAt: AbsoluteDate }>, account?: { __typename?: 'Account', type: string, externalId: string, description: string } | null, rebalance: { __typename?: 'PlanRebalanceStatus', outcome: PlanRebalanceOutcome, cause?: string | null }, paymentMethod?: { __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountNumber: string, accountName: string, gateway: string, name?: string | null } | null } | null } | null } };

export type SearchAudienceQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SearchAudienceQuery = { __typename?: 'Query', contacts: { __typename?: 'ContactConnection', total: number, edges?: Array<{ __typename?: 'ContactEdge', cursor: string, node?: { __typename?: 'Contact', id: string, title: string, givenName: string, familyName: string, email: string, emailVerified: boolean, mobile: string, mobileVerified: boolean, accounts?: Array<{ __typename?: 'Account', id: string, type: string, externalId: string, description: string, amountOwing: number, status: AccountStatus, standing?: { __typename?: 'AccountStanding', status?: string | null, paymentId?: string | null, instalmentPlanId?: string | null, instalmentPlanCancelledReason?: string | null, owner?: { __typename?: 'Owner', id: string, type: string } | null } | null }> | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };

export type SendAdminRequestedNotificationMutationVariables = Exact<{
  input: AdminRequestedNotificationInput;
}>;


export type SendAdminRequestedNotificationMutation = { __typename?: 'Mutation', sendAdminRequestedNotification?: { __typename?: 'SendAdminRequestedNotificationOutput', dryRun: boolean, body?: Array<string> | null } | null };

export type GetAuditLogsQueryVariables = Exact<{
  input: AuditLogSearchInput;
}>;


export type GetAuditLogsQuery = { __typename?: 'Query', auditLogs: { __typename?: 'AuditLogConnection', total: number, edges: Array<{ __typename?: 'AuditLogEdge', cursor: string, node: { __typename?: 'AuditLog', id: string, action: string, timestamp: string, ip?: string | null, adminId: string, adminName: string, correlationId?: string | null, isMutation: boolean, isSuccess: boolean } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };

export type GetBankByCodeQueryVariables = Exact<{
  code: Scalars['String']['input'];
  region: Scalars['String']['input'];
}>;


export type GetBankByCodeQuery = { __typename?: 'Query', bankByCode?: { __typename?: 'Bank', name: string, code: string } | null };

export type GetNotificationsBySearchQueryVariables = Exact<{
  contactId: Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetNotificationsBySearchQuery = { __typename?: 'Query', notifications: { __typename?: 'NotificationConnection', total: number, edges: Array<{ __typename?: 'NotificationEdge', cursor: string, node: { __typename?: 'Notification', id: string, name: string, sentAt: string, status: NotificationStatus, channel: NotificationChannel, preview: string } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };

export type GetPaymentStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentStatsQuery = { __typename?: 'Query', paymentStats: { __typename?: 'PaymentStats', totalSuccessful: number, totalSuccessfulLastMonth: number, totalFailed: number, totalFailedLastMonth: number, totalScheduled: number, totalScheduledLastMonth: number } };

export type GetPaymentsByModeQueryVariables = Exact<{
  mode: Array<PaymentMode> | PaymentMode;
}>;


export type GetPaymentsByModeQuery = { __typename?: 'Query', payments: { __typename?: 'PaymentConnection', total: number, edges?: Array<{ __typename?: 'PaymentEdge', cursor: string, node?: { __typename?: 'Payment', id: string, mode: PaymentMode, currency: string, amount: number, accountId: string, instalmentId?: string | null, instalmentPlanId?: string | null, testMode: boolean, status: PaymentStatus, refundStatus?: PaymentRefundStatus | null, gateway: string, gatewayTransactionId?: string | null, processedAt?: string | null, succeededAt?: string | null, failedAt?: string | null, refundedAt?: string | null, createdAt?: string | null, paymentMethod?: { __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountNumber: string, accountName: string, gateway: string, name?: string | null } | null } | null, account?: { __typename?: 'Account', id: string, type: string, externalId: string, description: string, amountOwing: number } | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };

export type GetPaymentsBySearchQueryVariables = Exact<{
  mode?: InputMaybe<Array<PaymentMode> | PaymentMode>;
  accountId?: InputMaybe<Scalars['String']['input']>;
  contactId?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetPaymentsBySearchQuery = { __typename?: 'Query', payments: { __typename?: 'PaymentConnection', total: number, edges?: Array<{ __typename?: 'PaymentEdge', cursor: string, node?: { __typename?: 'Payment', id: string, mode: PaymentMode, currency: string, amount: number, accountId: string, instalmentId?: string | null, instalmentPlanId?: string | null, testMode: boolean, status: PaymentStatus, failedReason?: string | null, refundStatus?: PaymentRefundStatus | null, gateway: string, gatewayTransactionId?: string | null, createdAt?: string | null, processedAt?: string | null, succeededAt?: string | null, failedAt?: string | null, refundedAt?: string | null, paymentMethod?: { __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountNumber: string, accountName: string, gateway: string, name?: string | null } | null } | null, account?: { __typename?: 'Account', id: string, type: string, externalId: string, description: string, amountOwing: number } | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };

export type ScheduledPaymentFragment = { __typename?: 'ScheduledPayment', amount: number, accountId: string, instalmentId: string, instalmentPlanId: string, dueAt: AbsoluteDate, paymentMethod?: { __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountNumber: string, accountName: string, gateway: string, name?: string | null } | null } | null, account?: { __typename?: 'Account', id: string, type: string, externalId: string, description: string, amountOwing: number } | null };

export type GetScheduledPaymentsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetScheduledPaymentsQuery = { __typename?: 'Query', scheduledPayments: { __typename?: 'ScheduledPaymentConnection', total: number, edges?: Array<{ __typename?: 'ScheduledPaymentEdge', cursor: string, node?: { __typename?: 'ScheduledPayment', amount: number, accountId: string, instalmentId: string, instalmentPlanId: string, dueAt: AbsoluteDate, paymentMethod?: { __typename?: 'PaymentMethod', id: string, type: PaymentMethodType, status: PaymentMethodStatus, card?: { __typename?: 'PaymentMethodCard', holderName: string, brand: string, last4: string, expiryMonth: number, expiryYear: number, gateway: string } | null, bank?: { __typename?: 'PaymentMethodBank', bsb: string, name?: string | null, accountName: string, accountNumber: string, gateway: string } | null, nzBank?: { __typename?: 'PaymentMethodNZBank', accountNumber: string, accountName: string, gateway: string, name?: string | null } | null } | null, account?: { __typename?: 'Account', id: string, type: string, externalId: string, description: string, amountOwing: number } | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };

export type GetRequestedPlansBySearchQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  contactId?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetRequestedPlansBySearchQuery = { __typename?: 'Query', planRequests: { __typename?: 'PlanRequestConnection', total: number, edges: Array<{ __typename?: 'PlanRequestEdge', cursor: string, node: { __typename?: 'PlanRequest', id: string, billerId: string, accountId: string, paymentMethodId: string, frequency: InstalmentFrequency, startAt: AbsoluteDate, instalmentAmount: number, contactId?: string | null, createdAt: string, updatedAt: string, rejectedAt?: string | null, acceptedAt?: string | null } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };

export type GetSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSessionQuery = { __typename?: 'Query', session: { __typename?: 'Session', email: string, adminId: string, expiresAt: string, billerId: string, billerName: string, billerSlug: string } };

export const AccountFragmentDoc = gql`
    fragment account on Account {
  id
  amountOwing
  description
  externalId
  meta
  status
  type
  setupBreakdown {
    amount
    label
  }
  targetInstalments {
    amount
    dueAt
  }
  standing {
    status
    paymentId
    instalmentPlanId
    instalmentPlanCancelledReason
  }
  contacts {
    id
    givenName
    familyName
    title
    email
    emailVerified
    mobile
    mobileVerified
  }
}
    `;
export const ContactFragmentDoc = gql`
    fragment contact on Contact {
  id
  givenName
  familyName
  title
  email
  emailVerified
  mobile
  mobileVerified
  paymentMethods {
    id
    type
    card {
      holderName
      brand
      last4
      expiryMonth
      expiryYear
      gateway
    }
    bank {
      bsb
      name
      accountName
      accountNumber
      gateway
    }
    nzBank {
      accountName
      accountNumber
      gateway
      name
    }
    status
    reason
  }
  accounts {
    id
    type
    externalId
    description
    amountOwing
    standing {
      status
      paymentId
      instalmentPlanId
    }
  }
}
    `;
export const InstalmentPlanDetailFragmentDoc = gql`
    fragment InstalmentPlanDetail on InstalmentPlan {
  id
  mode
  frequency
  instalments {
    instalmentId
    status
    skippedAt
    cancelledAt
    paidAt
    missedAt
    amount
    dueAt
  }
  currency
  amount
  amountPaid
  amountDue
  nextInstalmentDueAt
  accountId
  billerId
  paymentMethodId
  testMode
  status
  cancelledReason
  createdAt
  cancelledAt
  completedAt
  account {
    type
    externalId
    description
  }
  rebalance {
    outcome
    cause
  }
  paymentMethod {
    id
    type
    card {
      holderName
      brand
      last4
      expiryMonth
      expiryYear
      gateway
    }
    bank {
      bsb
      name
      accountName
      accountNumber
      gateway
    }
    nzBank {
      accountNumber
      accountName
      gateway
      name
    }
    status
  }
}
    `;
export const PayerFragmentDoc = gql`
    fragment payer on Payer {
  id
  payerId
  meta
  updatedAt
  createdAt
  deletedAt
  deletedReason
  account {
    id
    status
    externalId
    status
    description
    amountOwing
    type
    standing {
      status
      paymentId
      instalmentPlanId
      instalmentPlanCancelledReason
    }
    setupBreakdown {
      amount
      label
    }
    targetInstalments {
      amount
      dueAt
    }
  }
  paymentMethods {
    id
    type
    card {
      holderName
      brand
      last4
      expiryMonth
      expiryYear
      gateway
    }
    bank {
      bsb
      name
      accountName
      accountNumber
      gateway
    }
    nzBank {
      accountName
      accountNumber
      gateway
      name
    }
    status
  }
}
    `;
export const ScheduledPaymentFragmentDoc = gql`
    fragment scheduledPayment on ScheduledPayment {
  amount
  accountId
  instalmentId
  instalmentPlanId
  dueAt
  paymentMethod {
    id
    type
    card {
      holderName
      brand
      last4
      expiryMonth
      expiryYear
      gateway
    }
    bank {
      bsb
      name
      accountName
      accountNumber
      gateway
    }
    nzBank {
      accountNumber
      accountName
      gateway
      name
    }
    status
  }
  account {
    id
    type
    externalId
    description
    amountOwing
  }
}
    `;
export const GetActionsBySearchDocument = gql`
    query getActionsBySearch($search: String, $offset: Int) {
  actions(search: $search, offset: $offset) {
    edges {
      cursor
      node {
        id
        type
        linkTo
        name
        description
        timestamp
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    total
  }
}
    `;

/**
 * __useGetActionsBySearchQuery__
 *
 * To run a query within a React component, call `useGetActionsBySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionsBySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionsBySearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetActionsBySearchQuery(baseOptions?: Apollo.QueryHookOptions<GetActionsBySearchQuery, GetActionsBySearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActionsBySearchQuery, GetActionsBySearchQueryVariables>(GetActionsBySearchDocument, options);
      }
export function useGetActionsBySearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActionsBySearchQuery, GetActionsBySearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActionsBySearchQuery, GetActionsBySearchQueryVariables>(GetActionsBySearchDocument, options);
        }
export type GetActionsBySearchQueryHookResult = ReturnType<typeof useGetActionsBySearchQuery>;
export type GetActionsBySearchLazyQueryHookResult = ReturnType<typeof useGetActionsBySearchLazyQuery>;
export type GetActionsBySearchQueryResult = Apollo.QueryResult<GetActionsBySearchQuery, GetActionsBySearchQueryVariables>;
export const AddPaymentMethodDocument = gql`
    mutation addPaymentMethod($input: AddPaymentMethodInput!) {
  addPaymentMethod(input: $input) {
    contact {
      ...contact
    }
    paymentMethodId
  }
}
    ${ContactFragmentDoc}`;
export type AddPaymentMethodMutationFn = Apollo.MutationFunction<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>;

/**
 * __useAddPaymentMethodMutation__
 *
 * To run a mutation, you first call `useAddPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentMethodMutation, { data, loading, error }] = useAddPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>(AddPaymentMethodDocument, options);
      }
export type AddPaymentMethodMutationHookResult = ReturnType<typeof useAddPaymentMethodMutation>;
export type AddPaymentMethodMutationResult = Apollo.MutationResult<AddPaymentMethodMutation>;
export type AddPaymentMethodMutationOptions = Apollo.BaseMutationOptions<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>;
export const ArchivePayerDocument = gql`
    mutation archivePayer($input: ArchivePayerInput!) {
  archivePayer(input: $input) {
    ...payer
  }
}
    ${PayerFragmentDoc}`;
export type ArchivePayerMutationFn = Apollo.MutationFunction<ArchivePayerMutation, ArchivePayerMutationVariables>;

/**
 * __useArchivePayerMutation__
 *
 * To run a mutation, you first call `useArchivePayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchivePayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archivePayerMutation, { data, loading, error }] = useArchivePayerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchivePayerMutation(baseOptions?: Apollo.MutationHookOptions<ArchivePayerMutation, ArchivePayerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchivePayerMutation, ArchivePayerMutationVariables>(ArchivePayerDocument, options);
      }
export type ArchivePayerMutationHookResult = ReturnType<typeof useArchivePayerMutation>;
export type ArchivePayerMutationResult = Apollo.MutationResult<ArchivePayerMutation>;
export type ArchivePayerMutationOptions = Apollo.BaseMutationOptions<ArchivePayerMutation, ArchivePayerMutationVariables>;
export const CancelInstalmentPlanDocument = gql`
    mutation cancelInstalmentPlan($input: CancelInstalmentPlanInput!) {
  cancelInstalmentPlan(input: $input) {
    ...InstalmentPlanDetail
  }
}
    ${InstalmentPlanDetailFragmentDoc}`;
export type CancelInstalmentPlanMutationFn = Apollo.MutationFunction<CancelInstalmentPlanMutation, CancelInstalmentPlanMutationVariables>;

/**
 * __useCancelInstalmentPlanMutation__
 *
 * To run a mutation, you first call `useCancelInstalmentPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInstalmentPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInstalmentPlanMutation, { data, loading, error }] = useCancelInstalmentPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelInstalmentPlanMutation(baseOptions?: Apollo.MutationHookOptions<CancelInstalmentPlanMutation, CancelInstalmentPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelInstalmentPlanMutation, CancelInstalmentPlanMutationVariables>(CancelInstalmentPlanDocument, options);
      }
export type CancelInstalmentPlanMutationHookResult = ReturnType<typeof useCancelInstalmentPlanMutation>;
export type CancelInstalmentPlanMutationResult = Apollo.MutationResult<CancelInstalmentPlanMutation>;
export type CancelInstalmentPlanMutationOptions = Apollo.BaseMutationOptions<CancelInstalmentPlanMutation, CancelInstalmentPlanMutationVariables>;
export const CreateContactDocument = gql`
    mutation createContact($input: CreateContactInput!) {
  createContact(input: $input) {
    ...contact
  }
}
    ${ContactFragmentDoc}`;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const CreateInstalmentPlanDocument = gql`
    mutation createInstalmentPlan($input: CreateInstalmentPlanInput!) {
  createInstalmentPlan(input: $input) {
    id
    mode
    instalments {
      instalmentId
      amount
      dueAt
      status
    }
    currency
    amount
    amountPaid
    amountDue
    nextInstalmentDueAt
    accountId
    billerId
    frequency
    testMode
  }
}
    `;
export type CreateInstalmentPlanMutationFn = Apollo.MutationFunction<CreateInstalmentPlanMutation, CreateInstalmentPlanMutationVariables>;

/**
 * __useCreateInstalmentPlanMutation__
 *
 * To run a mutation, you first call `useCreateInstalmentPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInstalmentPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInstalmentPlanMutation, { data, loading, error }] = useCreateInstalmentPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInstalmentPlanMutation(baseOptions?: Apollo.MutationHookOptions<CreateInstalmentPlanMutation, CreateInstalmentPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInstalmentPlanMutation, CreateInstalmentPlanMutationVariables>(CreateInstalmentPlanDocument, options);
      }
export type CreateInstalmentPlanMutationHookResult = ReturnType<typeof useCreateInstalmentPlanMutation>;
export type CreateInstalmentPlanMutationResult = Apollo.MutationResult<CreateInstalmentPlanMutation>;
export type CreateInstalmentPlanMutationOptions = Apollo.BaseMutationOptions<CreateInstalmentPlanMutation, CreateInstalmentPlanMutationVariables>;
export const CreatePayerDocument = gql`
    mutation createPayer($input: CreatePayerInput!) {
  createPayer(input: $input) {
    ...payer
  }
}
    ${PayerFragmentDoc}`;
export type CreatePayerMutationFn = Apollo.MutationFunction<CreatePayerMutation, CreatePayerMutationVariables>;

/**
 * __useCreatePayerMutation__
 *
 * To run a mutation, you first call `useCreatePayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayerMutation, { data, loading, error }] = useCreatePayerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePayerMutation(baseOptions?: Apollo.MutationHookOptions<CreatePayerMutation, CreatePayerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePayerMutation, CreatePayerMutationVariables>(CreatePayerDocument, options);
      }
export type CreatePayerMutationHookResult = ReturnType<typeof useCreatePayerMutation>;
export type CreatePayerMutationResult = Apollo.MutationResult<CreatePayerMutation>;
export type CreatePayerMutationOptions = Apollo.BaseMutationOptions<CreatePayerMutation, CreatePayerMutationVariables>;
export const GetAccountByIdDocument = gql`
    query getAccountById($id: ID!) {
  account(id: $id) {
    ...account
  }
}
    ${AccountFragmentDoc}`;

/**
 * __useGetAccountByIdQuery__
 *
 * To run a query within a React component, call `useGetAccountByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAccountByIdQuery, GetAccountByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountByIdQuery, GetAccountByIdQueryVariables>(GetAccountByIdDocument, options);
      }
export function useGetAccountByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountByIdQuery, GetAccountByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountByIdQuery, GetAccountByIdQueryVariables>(GetAccountByIdDocument, options);
        }
export type GetAccountByIdQueryHookResult = ReturnType<typeof useGetAccountByIdQuery>;
export type GetAccountByIdLazyQueryHookResult = ReturnType<typeof useGetAccountByIdLazyQuery>;
export type GetAccountByIdQueryResult = Apollo.QueryResult<GetAccountByIdQuery, GetAccountByIdQueryVariables>;
export const GetAccountByExternalIdDocument = gql`
    query getAccountByExternalId($externalId: String!, $type: String!) {
  account(externalId: $externalId, type: $type) {
    ...account
  }
}
    ${AccountFragmentDoc}`;

/**
 * __useGetAccountByExternalIdQuery__
 *
 * To run a query within a React component, call `useGetAccountByExternalIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountByExternalIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountByExternalIdQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetAccountByExternalIdQuery(baseOptions: Apollo.QueryHookOptions<GetAccountByExternalIdQuery, GetAccountByExternalIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountByExternalIdQuery, GetAccountByExternalIdQueryVariables>(GetAccountByExternalIdDocument, options);
      }
export function useGetAccountByExternalIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountByExternalIdQuery, GetAccountByExternalIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountByExternalIdQuery, GetAccountByExternalIdQueryVariables>(GetAccountByExternalIdDocument, options);
        }
export type GetAccountByExternalIdQueryHookResult = ReturnType<typeof useGetAccountByExternalIdQuery>;
export type GetAccountByExternalIdLazyQueryHookResult = ReturnType<typeof useGetAccountByExternalIdLazyQuery>;
export type GetAccountByExternalIdQueryResult = Apollo.QueryResult<GetAccountByExternalIdQuery, GetAccountByExternalIdQueryVariables>;
export const GetAccountDocument = gql`
    query getAccount($id: ID, $externalId: String, $type: String) {
  account(id: $id, externalId: $externalId, type: $type) {
    ...account
  }
}
    ${AccountFragmentDoc}`;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *      externalId: // value for 'externalId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetAccountQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
      }
export function useGetAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
        }
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<typeof useGetAccountLazyQuery>;
export type GetAccountQueryResult = Apollo.QueryResult<GetAccountQuery, GetAccountQueryVariables>;
export const GetAccountTypeDocument = gql`
    query getAccountType {
  accountTypes
}
    `;

/**
 * __useGetAccountTypeQuery__
 *
 * To run a query within a React component, call `useGetAccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountTypeQuery, GetAccountTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountTypeQuery, GetAccountTypeQueryVariables>(GetAccountTypeDocument, options);
      }
export function useGetAccountTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountTypeQuery, GetAccountTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountTypeQuery, GetAccountTypeQueryVariables>(GetAccountTypeDocument, options);
        }
export type GetAccountTypeQueryHookResult = ReturnType<typeof useGetAccountTypeQuery>;
export type GetAccountTypeLazyQueryHookResult = ReturnType<typeof useGetAccountTypeLazyQuery>;
export type GetAccountTypeQueryResult = Apollo.QueryResult<GetAccountTypeQuery, GetAccountTypeQueryVariables>;
export const GetAccountsAllDocument = gql`
    query getAccountsAll {
  accounts {
    edges {
      node {
        ...account
      }
    }
  }
}
    ${AccountFragmentDoc}`;

/**
 * __useGetAccountsAllQuery__
 *
 * To run a query within a React component, call `useGetAccountsAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountsAllQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountsAllQuery, GetAccountsAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountsAllQuery, GetAccountsAllQueryVariables>(GetAccountsAllDocument, options);
      }
export function useGetAccountsAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountsAllQuery, GetAccountsAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountsAllQuery, GetAccountsAllQueryVariables>(GetAccountsAllDocument, options);
        }
export type GetAccountsAllQueryHookResult = ReturnType<typeof useGetAccountsAllQuery>;
export type GetAccountsAllLazyQueryHookResult = ReturnType<typeof useGetAccountsAllLazyQuery>;
export type GetAccountsAllQueryResult = Apollo.QueryResult<GetAccountsAllQuery, GetAccountsAllQueryVariables>;
export const GetAccountsSearchDocument = gql`
    query getAccountsSearch($search: String, $first: Int, $last: Int, $offset: Int, $types: [String]) {
  accounts(
    search: $search
    first: $first
    last: $last
    offset: $offset
    types: $types
  ) {
    edges {
      cursor
      node {
        ...account
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    total
  }
}
    ${AccountFragmentDoc}`;

/**
 * __useGetAccountsSearchQuery__
 *
 * To run a query within a React component, call `useGetAccountsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *      types: // value for 'types'
 *   },
 * });
 */
export function useGetAccountsSearchQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountsSearchQuery, GetAccountsSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountsSearchQuery, GetAccountsSearchQueryVariables>(GetAccountsSearchDocument, options);
      }
export function useGetAccountsSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountsSearchQuery, GetAccountsSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountsSearchQuery, GetAccountsSearchQueryVariables>(GetAccountsSearchDocument, options);
        }
export type GetAccountsSearchQueryHookResult = ReturnType<typeof useGetAccountsSearchQuery>;
export type GetAccountsSearchLazyQueryHookResult = ReturnType<typeof useGetAccountsSearchLazyQuery>;
export type GetAccountsSearchQueryResult = Apollo.QueryResult<GetAccountsSearchQuery, GetAccountsSearchQueryVariables>;
export const GetAudienceStatsDocument = gql`
    query getAudienceStats {
  audienceStats {
    totalAccounts
    totalAccountsLastMonth
    totalContacts
    totalContactsLastMonth
    activeInstalmentPlans
    activeInstalmentPlansLastMonth
  }
}
    `;

/**
 * __useGetAudienceStatsQuery__
 *
 * To run a query within a React component, call `useGetAudienceStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAudienceStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAudienceStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAudienceStatsQuery(baseOptions?: Apollo.QueryHookOptions<GetAudienceStatsQuery, GetAudienceStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAudienceStatsQuery, GetAudienceStatsQueryVariables>(GetAudienceStatsDocument, options);
      }
export function useGetAudienceStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAudienceStatsQuery, GetAudienceStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAudienceStatsQuery, GetAudienceStatsQueryVariables>(GetAudienceStatsDocument, options);
        }
export type GetAudienceStatsQueryHookResult = ReturnType<typeof useGetAudienceStatsQuery>;
export type GetAudienceStatsLazyQueryHookResult = ReturnType<typeof useGetAudienceStatsLazyQuery>;
export type GetAudienceStatsQueryResult = Apollo.QueryResult<GetAudienceStatsQuery, GetAudienceStatsQueryVariables>;
export const GetContactByIdDocument = gql`
    query getContactById($id: ID!) {
  contact(id: $id) {
    ...contact
  }
}
    ${ContactFragmentDoc}`;

/**
 * __useGetContactByIdQuery__
 *
 * To run a query within a React component, call `useGetContactByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactByIdQuery(baseOptions: Apollo.QueryHookOptions<GetContactByIdQuery, GetContactByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactByIdQuery, GetContactByIdQueryVariables>(GetContactByIdDocument, options);
      }
export function useGetContactByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactByIdQuery, GetContactByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactByIdQuery, GetContactByIdQueryVariables>(GetContactByIdDocument, options);
        }
export type GetContactByIdQueryHookResult = ReturnType<typeof useGetContactByIdQuery>;
export type GetContactByIdLazyQueryHookResult = ReturnType<typeof useGetContactByIdLazyQuery>;
export type GetContactByIdQueryResult = Apollo.QueryResult<GetContactByIdQuery, GetContactByIdQueryVariables>;
export const GetContactByMobileDocument = gql`
    query getContactByMobile($mobile: PhoneNumber!) {
  contact(mobile: $mobile) {
    ...contact
  }
}
    ${ContactFragmentDoc}`;

/**
 * __useGetContactByMobileQuery__
 *
 * To run a query within a React component, call `useGetContactByMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactByMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactByMobileQuery({
 *   variables: {
 *      mobile: // value for 'mobile'
 *   },
 * });
 */
export function useGetContactByMobileQuery(baseOptions: Apollo.QueryHookOptions<GetContactByMobileQuery, GetContactByMobileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactByMobileQuery, GetContactByMobileQueryVariables>(GetContactByMobileDocument, options);
      }
export function useGetContactByMobileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactByMobileQuery, GetContactByMobileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactByMobileQuery, GetContactByMobileQueryVariables>(GetContactByMobileDocument, options);
        }
export type GetContactByMobileQueryHookResult = ReturnType<typeof useGetContactByMobileQuery>;
export type GetContactByMobileLazyQueryHookResult = ReturnType<typeof useGetContactByMobileLazyQuery>;
export type GetContactByMobileQueryResult = Apollo.QueryResult<GetContactByMobileQuery, GetContactByMobileQueryVariables>;
export const GetContactByEmailDocument = gql`
    query getContactByEmail($email: EmailAddress!) {
  contact(email: $email) {
    ...contact
  }
}
    ${ContactFragmentDoc}`;

/**
 * __useGetContactByEmailQuery__
 *
 * To run a query within a React component, call `useGetContactByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetContactByEmailQuery(baseOptions: Apollo.QueryHookOptions<GetContactByEmailQuery, GetContactByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactByEmailQuery, GetContactByEmailQueryVariables>(GetContactByEmailDocument, options);
      }
export function useGetContactByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactByEmailQuery, GetContactByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactByEmailQuery, GetContactByEmailQueryVariables>(GetContactByEmailDocument, options);
        }
export type GetContactByEmailQueryHookResult = ReturnType<typeof useGetContactByEmailQuery>;
export type GetContactByEmailLazyQueryHookResult = ReturnType<typeof useGetContactByEmailLazyQuery>;
export type GetContactByEmailQueryResult = Apollo.QueryResult<GetContactByEmailQuery, GetContactByEmailQueryVariables>;
export const GetContactByExternalIdDocument = gql`
    query getContactByExternalId($externalId: String!) {
  contact(externalId: $externalId) {
    ...contact
  }
}
    ${ContactFragmentDoc}`;

/**
 * __useGetContactByExternalIdQuery__
 *
 * To run a query within a React component, call `useGetContactByExternalIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactByExternalIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactByExternalIdQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useGetContactByExternalIdQuery(baseOptions: Apollo.QueryHookOptions<GetContactByExternalIdQuery, GetContactByExternalIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactByExternalIdQuery, GetContactByExternalIdQueryVariables>(GetContactByExternalIdDocument, options);
      }
export function useGetContactByExternalIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactByExternalIdQuery, GetContactByExternalIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactByExternalIdQuery, GetContactByExternalIdQueryVariables>(GetContactByExternalIdDocument, options);
        }
export type GetContactByExternalIdQueryHookResult = ReturnType<typeof useGetContactByExternalIdQuery>;
export type GetContactByExternalIdLazyQueryHookResult = ReturnType<typeof useGetContactByExternalIdLazyQuery>;
export type GetContactByExternalIdQueryResult = Apollo.QueryResult<GetContactByExternalIdQuery, GetContactByExternalIdQueryVariables>;
export const GetContactDocument = gql`
    query getContact($id: ID, $email: EmailAddress, $externalId: String, $mobile: PhoneNumber) {
  contact(id: $id, email: $email, externalId: $externalId, mobile: $mobile) {
    ...contact
  }
}
    ${ContactFragmentDoc}`;

/**
 * __useGetContactQuery__
 *
 * To run a query within a React component, call `useGetContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      externalId: // value for 'externalId'
 *      mobile: // value for 'mobile'
 *   },
 * });
 */
export function useGetContactQuery(baseOptions?: Apollo.QueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, options);
      }
export function useGetContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, options);
        }
export type GetContactQueryHookResult = ReturnType<typeof useGetContactQuery>;
export type GetContactLazyQueryHookResult = ReturnType<typeof useGetContactLazyQuery>;
export type GetContactQueryResult = Apollo.QueryResult<GetContactQuery, GetContactQueryVariables>;
export const GetFilesDocument = gql`
    query getFiles($prefix: FilePrefix!) {
  files(prefix: $prefix) {
    fileFormat
    fileType
    fileName
    status
    createdAt
    uploadedVia
    uploadedBy
    signedUrl
  }
}
    `;

/**
 * __useGetFilesQuery__
 *
 * To run a query within a React component, call `useGetFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilesQuery({
 *   variables: {
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useGetFilesQuery(baseOptions: Apollo.QueryHookOptions<GetFilesQuery, GetFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilesQuery, GetFilesQueryVariables>(GetFilesDocument, options);
      }
export function useGetFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilesQuery, GetFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilesQuery, GetFilesQueryVariables>(GetFilesDocument, options);
        }
export type GetFilesQueryHookResult = ReturnType<typeof useGetFilesQuery>;
export type GetFilesLazyQueryHookResult = ReturnType<typeof useGetFilesLazyQuery>;
export type GetFilesQueryResult = Apollo.QueryResult<GetFilesQuery, GetFilesQueryVariables>;
export const GetInstalmentPlansDocument = gql`
    query getInstalmentPlans($accountId: String, $contactId: String) {
  instalmentPlans(accountId: $accountId, contactId: $contactId) {
    ...InstalmentPlanDetail
  }
}
    ${InstalmentPlanDetailFragmentDoc}`;

/**
 * __useGetInstalmentPlansQuery__
 *
 * To run a query within a React component, call `useGetInstalmentPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstalmentPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstalmentPlansQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useGetInstalmentPlansQuery(baseOptions?: Apollo.QueryHookOptions<GetInstalmentPlansQuery, GetInstalmentPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstalmentPlansQuery, GetInstalmentPlansQueryVariables>(GetInstalmentPlansDocument, options);
      }
export function useGetInstalmentPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstalmentPlansQuery, GetInstalmentPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstalmentPlansQuery, GetInstalmentPlansQueryVariables>(GetInstalmentPlansDocument, options);
        }
export type GetInstalmentPlansQueryHookResult = ReturnType<typeof useGetInstalmentPlansQuery>;
export type GetInstalmentPlansLazyQueryHookResult = ReturnType<typeof useGetInstalmentPlansLazyQuery>;
export type GetInstalmentPlansQueryResult = Apollo.QueryResult<GetInstalmentPlansQuery, GetInstalmentPlansQueryVariables>;
export const GetInstalmentPlanEventsDocument = gql`
    query getInstalmentPlanEvents($id: ID!) {
  instalmentPlan(id: $id) {
    events {
      id
      type
      data
      createdAt
    }
  }
}
    `;

/**
 * __useGetInstalmentPlanEventsQuery__
 *
 * To run a query within a React component, call `useGetInstalmentPlanEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstalmentPlanEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstalmentPlanEventsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInstalmentPlanEventsQuery(baseOptions: Apollo.QueryHookOptions<GetInstalmentPlanEventsQuery, GetInstalmentPlanEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstalmentPlanEventsQuery, GetInstalmentPlanEventsQueryVariables>(GetInstalmentPlanEventsDocument, options);
      }
export function useGetInstalmentPlanEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstalmentPlanEventsQuery, GetInstalmentPlanEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstalmentPlanEventsQuery, GetInstalmentPlanEventsQueryVariables>(GetInstalmentPlanEventsDocument, options);
        }
export type GetInstalmentPlanEventsQueryHookResult = ReturnType<typeof useGetInstalmentPlanEventsQuery>;
export type GetInstalmentPlanEventsLazyQueryHookResult = ReturnType<typeof useGetInstalmentPlanEventsLazyQuery>;
export type GetInstalmentPlanEventsQueryResult = Apollo.QueryResult<GetInstalmentPlanEventsQuery, GetInstalmentPlanEventsQueryVariables>;
export const GetPaymentsDocument = gql`
    query getPayments($accountId: String, $contactId: String) {
  payments(accountId: $accountId, contactId: $contactId) {
    edges {
      cursor
      node {
        id
        mode
        currency
        amount
        accountId
        instalmentId
        instalmentPlanId
        testMode
        status
        failedReason
        refundStatus
        gateway
        gatewayTransactionId
        processedAt
        succeededAt
        failedAt
        refundedAt
        paymentMethod {
          id
          type
          card {
            holderName
            brand
            last4
            expiryMonth
            expiryYear
            gateway
          }
          bank {
            bsb
            accountName
            accountNumber
            gateway
          }
          nzBank {
            accountNumber
            accountName
            gateway
            name
          }
          status
        }
        account {
          id
          type
          externalId
          description
          amountOwing
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    total
  }
}
    `;

/**
 * __useGetPaymentsQuery__
 *
 * To run a query within a React component, call `useGetPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useGetPaymentsQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentsQuery, GetPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(GetPaymentsDocument, options);
      }
export function useGetPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentsQuery, GetPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(GetPaymentsDocument, options);
        }
export type GetPaymentsQueryHookResult = ReturnType<typeof useGetPaymentsQuery>;
export type GetPaymentsLazyQueryHookResult = ReturnType<typeof useGetPaymentsLazyQuery>;
export type GetPaymentsQueryResult = Apollo.QueryResult<GetPaymentsQuery, GetPaymentsQueryVariables>;
export const GetSignedUrlDocument = gql`
    mutation getSignedUrl($input: GetSignedUrlInput!) {
  getSignedUrl(input: $input) {
    fields
    url
  }
}
    `;
export type GetSignedUrlMutationFn = Apollo.MutationFunction<GetSignedUrlMutation, GetSignedUrlMutationVariables>;

/**
 * __useGetSignedUrlMutation__
 *
 * To run a mutation, you first call `useGetSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSignedUrlMutation, { data, loading, error }] = useGetSignedUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSignedUrlMutation(baseOptions?: Apollo.MutationHookOptions<GetSignedUrlMutation, GetSignedUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetSignedUrlMutation, GetSignedUrlMutationVariables>(GetSignedUrlDocument, options);
      }
export type GetSignedUrlMutationHookResult = ReturnType<typeof useGetSignedUrlMutation>;
export type GetSignedUrlMutationResult = Apollo.MutationResult<GetSignedUrlMutation>;
export type GetSignedUrlMutationOptions = Apollo.BaseMutationOptions<GetSignedUrlMutation, GetSignedUrlMutationVariables>;
export const LookupAccountsDocument = gql`
    query lookupAccounts($lookup: String!) {
  accountLookup(lookup: $lookup) {
    description
    externalId
    accountType
  }
}
    `;

/**
 * __useLookupAccountsQuery__
 *
 * To run a query within a React component, call `useLookupAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupAccountsQuery({
 *   variables: {
 *      lookup: // value for 'lookup'
 *   },
 * });
 */
export function useLookupAccountsQuery(baseOptions: Apollo.QueryHookOptions<LookupAccountsQuery, LookupAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LookupAccountsQuery, LookupAccountsQueryVariables>(LookupAccountsDocument, options);
      }
export function useLookupAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LookupAccountsQuery, LookupAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LookupAccountsQuery, LookupAccountsQueryVariables>(LookupAccountsDocument, options);
        }
export type LookupAccountsQueryHookResult = ReturnType<typeof useLookupAccountsQuery>;
export type LookupAccountsLazyQueryHookResult = ReturnType<typeof useLookupAccountsLazyQuery>;
export type LookupAccountsQueryResult = Apollo.QueryResult<LookupAccountsQuery, LookupAccountsQueryVariables>;
export const GetPayersAllDocument = gql`
    query getPayersAll {
  payers {
    edges {
      node {
        ...payer
      }
    }
  }
}
    ${PayerFragmentDoc}`;

/**
 * __useGetPayersAllQuery__
 *
 * To run a query within a React component, call `useGetPayersAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayersAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayersAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPayersAllQuery(baseOptions?: Apollo.QueryHookOptions<GetPayersAllQuery, GetPayersAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayersAllQuery, GetPayersAllQueryVariables>(GetPayersAllDocument, options);
      }
export function useGetPayersAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayersAllQuery, GetPayersAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayersAllQuery, GetPayersAllQueryVariables>(GetPayersAllDocument, options);
        }
export type GetPayersAllQueryHookResult = ReturnType<typeof useGetPayersAllQuery>;
export type GetPayersAllLazyQueryHookResult = ReturnType<typeof useGetPayersAllLazyQuery>;
export type GetPayersAllQueryResult = Apollo.QueryResult<GetPayersAllQuery, GetPayersAllQueryVariables>;
export const GetPayerByIdDocument = gql`
    query getPayerById($id: ID!) {
  payer(id: $id) {
    ...payer
  }
}
    ${PayerFragmentDoc}`;

/**
 * __useGetPayerByIdQuery__
 *
 * To run a query within a React component, call `useGetPayerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPayerByIdQuery(baseOptions: Apollo.QueryHookOptions<GetPayerByIdQuery, GetPayerByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayerByIdQuery, GetPayerByIdQueryVariables>(GetPayerByIdDocument, options);
      }
export function useGetPayerByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayerByIdQuery, GetPayerByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayerByIdQuery, GetPayerByIdQueryVariables>(GetPayerByIdDocument, options);
        }
export type GetPayerByIdQueryHookResult = ReturnType<typeof useGetPayerByIdQuery>;
export type GetPayerByIdLazyQueryHookResult = ReturnType<typeof useGetPayerByIdLazyQuery>;
export type GetPayerByIdQueryResult = Apollo.QueryResult<GetPayerByIdQuery, GetPayerByIdQueryVariables>;
export const GetPayerDocument = gql`
    query getPayer($id: ID!) {
  payer(id: $id) {
    ...payer
  }
}
    ${PayerFragmentDoc}`;

/**
 * __useGetPayerQuery__
 *
 * To run a query within a React component, call `useGetPayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPayerQuery(baseOptions: Apollo.QueryHookOptions<GetPayerQuery, GetPayerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayerQuery, GetPayerQueryVariables>(GetPayerDocument, options);
      }
export function useGetPayerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayerQuery, GetPayerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayerQuery, GetPayerQueryVariables>(GetPayerDocument, options);
        }
export type GetPayerQueryHookResult = ReturnType<typeof useGetPayerQuery>;
export type GetPayerLazyQueryHookResult = ReturnType<typeof useGetPayerLazyQuery>;
export type GetPayerQueryResult = Apollo.QueryResult<GetPayerQuery, GetPayerQueryVariables>;
export const GetPayersSearchDocument = gql`
    query getPayersSearch($search: String, $first: Int, $last: Int, $offset: Int) {
  payers(search: $search, first: $first, last: $last, offset: $offset) {
    edges {
      cursor
      node {
        ...payer
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    total
  }
}
    ${PayerFragmentDoc}`;

/**
 * __useGetPayersSearchQuery__
 *
 * To run a query within a React component, call `useGetPayersSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayersSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayersSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPayersSearchQuery(baseOptions?: Apollo.QueryHookOptions<GetPayersSearchQuery, GetPayersSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayersSearchQuery, GetPayersSearchQueryVariables>(GetPayersSearchDocument, options);
      }
export function useGetPayersSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayersSearchQuery, GetPayersSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayersSearchQuery, GetPayersSearchQueryVariables>(GetPayersSearchDocument, options);
        }
export type GetPayersSearchQueryHookResult = ReturnType<typeof useGetPayersSearchQuery>;
export type GetPayersSearchLazyQueryHookResult = ReturnType<typeof useGetPayersSearchLazyQuery>;
export type GetPayersSearchQueryResult = Apollo.QueryResult<GetPayersSearchQuery, GetPayersSearchQueryVariables>;
export const RebalancePlanDocument = gql`
    mutation rebalancePlan($input: RebalancePlanInput!) {
  rebalancePlan(input: $input) {
    amount
    amountPaid
    amountDue
    instalments {
      instalmentId
      dueAt
      status
      amount
    }
  }
}
    `;
export type RebalancePlanMutationFn = Apollo.MutationFunction<RebalancePlanMutation, RebalancePlanMutationVariables>;

/**
 * __useRebalancePlanMutation__
 *
 * To run a mutation, you first call `useRebalancePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRebalancePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rebalancePlanMutation, { data, loading, error }] = useRebalancePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRebalancePlanMutation(baseOptions?: Apollo.MutationHookOptions<RebalancePlanMutation, RebalancePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RebalancePlanMutation, RebalancePlanMutationVariables>(RebalancePlanDocument, options);
      }
export type RebalancePlanMutationHookResult = ReturnType<typeof useRebalancePlanMutation>;
export type RebalancePlanMutationResult = Apollo.MutationResult<RebalancePlanMutation>;
export type RebalancePlanMutationOptions = Apollo.BaseMutationOptions<RebalancePlanMutation, RebalancePlanMutationVariables>;
export const ReviewPlanRequestDocument = gql`
    mutation reviewPlanRequest($input: ReviewPlanRequestInput!) {
  reviewPlanRequest(input: $input) {
    planRequest {
      id
      billerId
      accountId
      paymentMethodId
      frequency
      startAt
      instalmentAmount
      contactId
      createdAt
      updatedAt
      rejectedAt
      acceptedAt
    }
    instalmentPlan {
      ...InstalmentPlanDetail
    }
  }
}
    ${InstalmentPlanDetailFragmentDoc}`;
export type ReviewPlanRequestMutationFn = Apollo.MutationFunction<ReviewPlanRequestMutation, ReviewPlanRequestMutationVariables>;

/**
 * __useReviewPlanRequestMutation__
 *
 * To run a mutation, you first call `useReviewPlanRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewPlanRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewPlanRequestMutation, { data, loading, error }] = useReviewPlanRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewPlanRequestMutation(baseOptions?: Apollo.MutationHookOptions<ReviewPlanRequestMutation, ReviewPlanRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReviewPlanRequestMutation, ReviewPlanRequestMutationVariables>(ReviewPlanRequestDocument, options);
      }
export type ReviewPlanRequestMutationHookResult = ReturnType<typeof useReviewPlanRequestMutation>;
export type ReviewPlanRequestMutationResult = Apollo.MutationResult<ReviewPlanRequestMutation>;
export type ReviewPlanRequestMutationOptions = Apollo.BaseMutationOptions<ReviewPlanRequestMutation, ReviewPlanRequestMutationVariables>;
export const SearchAudienceDocument = gql`
    query searchAudience($search: String, $first: Int, $last: Int, $offset: Int) {
  contacts(search: $search, first: $first, last: $last, offset: $offset) {
    edges {
      cursor
      node {
        id
        title
        givenName
        familyName
        email
        emailVerified
        mobile
        mobileVerified
        accounts {
          id
          type
          externalId
          description
          amountOwing
          status
          standing {
            status
            paymentId
            instalmentPlanId
            instalmentPlanCancelledReason
            owner {
              id
              type
            }
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    total
  }
}
    `;

/**
 * __useSearchAudienceQuery__
 *
 * To run a query within a React component, call `useSearchAudienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAudienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAudienceQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSearchAudienceQuery(baseOptions?: Apollo.QueryHookOptions<SearchAudienceQuery, SearchAudienceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAudienceQuery, SearchAudienceQueryVariables>(SearchAudienceDocument, options);
      }
export function useSearchAudienceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAudienceQuery, SearchAudienceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAudienceQuery, SearchAudienceQueryVariables>(SearchAudienceDocument, options);
        }
export type SearchAudienceQueryHookResult = ReturnType<typeof useSearchAudienceQuery>;
export type SearchAudienceLazyQueryHookResult = ReturnType<typeof useSearchAudienceLazyQuery>;
export type SearchAudienceQueryResult = Apollo.QueryResult<SearchAudienceQuery, SearchAudienceQueryVariables>;
export const SendAdminRequestedNotificationDocument = gql`
    mutation sendAdminRequestedNotification($input: AdminRequestedNotificationInput!) {
  sendAdminRequestedNotification(input: $input) {
    dryRun
    body
  }
}
    `;
export type SendAdminRequestedNotificationMutationFn = Apollo.MutationFunction<SendAdminRequestedNotificationMutation, SendAdminRequestedNotificationMutationVariables>;

/**
 * __useSendAdminRequestedNotificationMutation__
 *
 * To run a mutation, you first call `useSendAdminRequestedNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAdminRequestedNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAdminRequestedNotificationMutation, { data, loading, error }] = useSendAdminRequestedNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendAdminRequestedNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendAdminRequestedNotificationMutation, SendAdminRequestedNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAdminRequestedNotificationMutation, SendAdminRequestedNotificationMutationVariables>(SendAdminRequestedNotificationDocument, options);
      }
export type SendAdminRequestedNotificationMutationHookResult = ReturnType<typeof useSendAdminRequestedNotificationMutation>;
export type SendAdminRequestedNotificationMutationResult = Apollo.MutationResult<SendAdminRequestedNotificationMutation>;
export type SendAdminRequestedNotificationMutationOptions = Apollo.BaseMutationOptions<SendAdminRequestedNotificationMutation, SendAdminRequestedNotificationMutationVariables>;
export const GetAuditLogsDocument = gql`
    query getAuditLogs($input: AuditLogSearchInput!) {
  auditLogs(input: $input) {
    edges {
      cursor
      node {
        id
        action
        timestamp
        ip
        adminId
        adminName
        correlationId
        isMutation
        isSuccess
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    total
  }
}
    `;

/**
 * __useGetAuditLogsQuery__
 *
 * To run a query within a React component, call `useGetAuditLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditLogsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAuditLogsQuery(baseOptions: Apollo.QueryHookOptions<GetAuditLogsQuery, GetAuditLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuditLogsQuery, GetAuditLogsQueryVariables>(GetAuditLogsDocument, options);
      }
export function useGetAuditLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuditLogsQuery, GetAuditLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuditLogsQuery, GetAuditLogsQueryVariables>(GetAuditLogsDocument, options);
        }
export type GetAuditLogsQueryHookResult = ReturnType<typeof useGetAuditLogsQuery>;
export type GetAuditLogsLazyQueryHookResult = ReturnType<typeof useGetAuditLogsLazyQuery>;
export type GetAuditLogsQueryResult = Apollo.QueryResult<GetAuditLogsQuery, GetAuditLogsQueryVariables>;
export const GetBankByCodeDocument = gql`
    query getBankByCode($code: String!, $region: String!) {
  bankByCode(code: $code, region: $region) {
    name
    code
  }
}
    `;

/**
 * __useGetBankByCodeQuery__
 *
 * To run a query within a React component, call `useGetBankByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useGetBankByCodeQuery(baseOptions: Apollo.QueryHookOptions<GetBankByCodeQuery, GetBankByCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBankByCodeQuery, GetBankByCodeQueryVariables>(GetBankByCodeDocument, options);
      }
export function useGetBankByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBankByCodeQuery, GetBankByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBankByCodeQuery, GetBankByCodeQueryVariables>(GetBankByCodeDocument, options);
        }
export type GetBankByCodeQueryHookResult = ReturnType<typeof useGetBankByCodeQuery>;
export type GetBankByCodeLazyQueryHookResult = ReturnType<typeof useGetBankByCodeLazyQuery>;
export type GetBankByCodeQueryResult = Apollo.QueryResult<GetBankByCodeQuery, GetBankByCodeQueryVariables>;
export const GetNotificationsBySearchDocument = gql`
    query getNotificationsBySearch($contactId: String!, $search: String, $offset: Int) {
  notifications(contactId: $contactId, search: $search, offset: $offset) {
    edges {
      cursor
      node {
        id
        name
        sentAt
        status
        channel
        preview
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    total
  }
}
    `;

/**
 * __useGetNotificationsBySearchQuery__
 *
 * To run a query within a React component, call `useGetNotificationsBySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsBySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsBySearchQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetNotificationsBySearchQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationsBySearchQuery, GetNotificationsBySearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsBySearchQuery, GetNotificationsBySearchQueryVariables>(GetNotificationsBySearchDocument, options);
      }
export function useGetNotificationsBySearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsBySearchQuery, GetNotificationsBySearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsBySearchQuery, GetNotificationsBySearchQueryVariables>(GetNotificationsBySearchDocument, options);
        }
export type GetNotificationsBySearchQueryHookResult = ReturnType<typeof useGetNotificationsBySearchQuery>;
export type GetNotificationsBySearchLazyQueryHookResult = ReturnType<typeof useGetNotificationsBySearchLazyQuery>;
export type GetNotificationsBySearchQueryResult = Apollo.QueryResult<GetNotificationsBySearchQuery, GetNotificationsBySearchQueryVariables>;
export const GetPaymentStatsDocument = gql`
    query getPaymentStats {
  paymentStats {
    totalSuccessful
    totalSuccessfulLastMonth
    totalFailed
    totalFailedLastMonth
    totalScheduled
    totalScheduledLastMonth
  }
}
    `;

/**
 * __useGetPaymentStatsQuery__
 *
 * To run a query within a React component, call `useGetPaymentStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentStatsQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentStatsQuery, GetPaymentStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentStatsQuery, GetPaymentStatsQueryVariables>(GetPaymentStatsDocument, options);
      }
export function useGetPaymentStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentStatsQuery, GetPaymentStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentStatsQuery, GetPaymentStatsQueryVariables>(GetPaymentStatsDocument, options);
        }
export type GetPaymentStatsQueryHookResult = ReturnType<typeof useGetPaymentStatsQuery>;
export type GetPaymentStatsLazyQueryHookResult = ReturnType<typeof useGetPaymentStatsLazyQuery>;
export type GetPaymentStatsQueryResult = Apollo.QueryResult<GetPaymentStatsQuery, GetPaymentStatsQueryVariables>;
export const GetPaymentsByModeDocument = gql`
    query getPaymentsByMode($mode: [PaymentMode!]!) {
  payments(mode: $mode) {
    edges {
      cursor
      node {
        id
        mode
        currency
        amount
        accountId
        instalmentId
        instalmentPlanId
        testMode
        status
        refundStatus
        gateway
        gatewayTransactionId
        processedAt
        succeededAt
        failedAt
        refundedAt
        createdAt
        paymentMethod {
          id
          type
          card {
            holderName
            brand
            last4
            expiryMonth
            expiryYear
            gateway
          }
          bank {
            bsb
            name
            accountName
            accountNumber
            gateway
          }
          nzBank {
            accountNumber
            accountName
            gateway
            name
          }
          status
        }
        account {
          id
          type
          externalId
          description
          amountOwing
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    total
  }
}
    `;

/**
 * __useGetPaymentsByModeQuery__
 *
 * To run a query within a React component, call `useGetPaymentsByModeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsByModeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsByModeQuery({
 *   variables: {
 *      mode: // value for 'mode'
 *   },
 * });
 */
export function useGetPaymentsByModeQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentsByModeQuery, GetPaymentsByModeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentsByModeQuery, GetPaymentsByModeQueryVariables>(GetPaymentsByModeDocument, options);
      }
export function useGetPaymentsByModeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentsByModeQuery, GetPaymentsByModeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentsByModeQuery, GetPaymentsByModeQueryVariables>(GetPaymentsByModeDocument, options);
        }
export type GetPaymentsByModeQueryHookResult = ReturnType<typeof useGetPaymentsByModeQuery>;
export type GetPaymentsByModeLazyQueryHookResult = ReturnType<typeof useGetPaymentsByModeLazyQuery>;
export type GetPaymentsByModeQueryResult = Apollo.QueryResult<GetPaymentsByModeQuery, GetPaymentsByModeQueryVariables>;
export const GetPaymentsBySearchDocument = gql`
    query getPaymentsBySearch($mode: [PaymentMode!], $accountId: String, $contactId: String, $search: String, $offset: Int) {
  payments(
    mode: $mode
    accountId: $accountId
    contactId: $contactId
    search: $search
    offset: $offset
  ) {
    edges {
      cursor
      node {
        id
        mode
        currency
        amount
        accountId
        instalmentId
        instalmentPlanId
        testMode
        status
        failedReason
        refundStatus
        gateway
        gatewayTransactionId
        createdAt
        processedAt
        succeededAt
        failedAt
        refundedAt
        paymentMethod {
          id
          type
          card {
            holderName
            brand
            last4
            expiryMonth
            expiryYear
            gateway
          }
          bank {
            bsb
            name
            accountName
            accountNumber
            gateway
          }
          nzBank {
            accountNumber
            accountName
            gateway
            name
          }
          status
        }
        account {
          id
          type
          externalId
          description
          amountOwing
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    total
  }
}
    `;

/**
 * __useGetPaymentsBySearchQuery__
 *
 * To run a query within a React component, call `useGetPaymentsBySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsBySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsBySearchQuery({
 *   variables: {
 *      mode: // value for 'mode'
 *      accountId: // value for 'accountId'
 *      contactId: // value for 'contactId'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPaymentsBySearchQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentsBySearchQuery, GetPaymentsBySearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentsBySearchQuery, GetPaymentsBySearchQueryVariables>(GetPaymentsBySearchDocument, options);
      }
export function useGetPaymentsBySearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentsBySearchQuery, GetPaymentsBySearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentsBySearchQuery, GetPaymentsBySearchQueryVariables>(GetPaymentsBySearchDocument, options);
        }
export type GetPaymentsBySearchQueryHookResult = ReturnType<typeof useGetPaymentsBySearchQuery>;
export type GetPaymentsBySearchLazyQueryHookResult = ReturnType<typeof useGetPaymentsBySearchLazyQuery>;
export type GetPaymentsBySearchQueryResult = Apollo.QueryResult<GetPaymentsBySearchQuery, GetPaymentsBySearchQueryVariables>;
export const GetScheduledPaymentsDocument = gql`
    query getScheduledPayments($search: String, $offset: Int) {
  scheduledPayments(search: $search, offset: $offset) {
    edges {
      cursor
      node {
        ...scheduledPayment
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    total
  }
}
    ${ScheduledPaymentFragmentDoc}`;

/**
 * __useGetScheduledPaymentsQuery__
 *
 * To run a query within a React component, call `useGetScheduledPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledPaymentsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetScheduledPaymentsQuery(baseOptions?: Apollo.QueryHookOptions<GetScheduledPaymentsQuery, GetScheduledPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduledPaymentsQuery, GetScheduledPaymentsQueryVariables>(GetScheduledPaymentsDocument, options);
      }
export function useGetScheduledPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduledPaymentsQuery, GetScheduledPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduledPaymentsQuery, GetScheduledPaymentsQueryVariables>(GetScheduledPaymentsDocument, options);
        }
export type GetScheduledPaymentsQueryHookResult = ReturnType<typeof useGetScheduledPaymentsQuery>;
export type GetScheduledPaymentsLazyQueryHookResult = ReturnType<typeof useGetScheduledPaymentsLazyQuery>;
export type GetScheduledPaymentsQueryResult = Apollo.QueryResult<GetScheduledPaymentsQuery, GetScheduledPaymentsQueryVariables>;
export const GetRequestedPlansBySearchDocument = gql`
    query getRequestedPlansBySearch($search: String, $contactId: String, $offset: Int) {
  planRequests(search: $search, contactId: $contactId, offset: $offset) {
    edges {
      cursor
      node {
        id
        billerId
        accountId
        paymentMethodId
        frequency
        startAt
        instalmentAmount
        contactId
        createdAt
        updatedAt
        rejectedAt
        acceptedAt
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    total
  }
}
    `;

/**
 * __useGetRequestedPlansBySearchQuery__
 *
 * To run a query within a React component, call `useGetRequestedPlansBySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestedPlansBySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestedPlansBySearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      contactId: // value for 'contactId'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetRequestedPlansBySearchQuery(baseOptions?: Apollo.QueryHookOptions<GetRequestedPlansBySearchQuery, GetRequestedPlansBySearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRequestedPlansBySearchQuery, GetRequestedPlansBySearchQueryVariables>(GetRequestedPlansBySearchDocument, options);
      }
export function useGetRequestedPlansBySearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRequestedPlansBySearchQuery, GetRequestedPlansBySearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRequestedPlansBySearchQuery, GetRequestedPlansBySearchQueryVariables>(GetRequestedPlansBySearchDocument, options);
        }
export type GetRequestedPlansBySearchQueryHookResult = ReturnType<typeof useGetRequestedPlansBySearchQuery>;
export type GetRequestedPlansBySearchLazyQueryHookResult = ReturnType<typeof useGetRequestedPlansBySearchLazyQuery>;
export type GetRequestedPlansBySearchQueryResult = Apollo.QueryResult<GetRequestedPlansBySearchQuery, GetRequestedPlansBySearchQueryVariables>;
export const GetSessionDocument = gql`
    query getSession {
  session {
    email
    adminId
    expiresAt
    billerId
    billerName
    billerSlug
  }
}
    `;

/**
 * __useGetSessionQuery__
 *
 * To run a query within a React component, call `useGetSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSessionQuery(baseOptions?: Apollo.QueryHookOptions<GetSessionQuery, GetSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSessionQuery, GetSessionQueryVariables>(GetSessionDocument, options);
      }
export function useGetSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionQuery, GetSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSessionQuery, GetSessionQueryVariables>(GetSessionDocument, options);
        }
export type GetSessionQueryHookResult = ReturnType<typeof useGetSessionQuery>;
export type GetSessionLazyQueryHookResult = ReturnType<typeof useGetSessionLazyQuery>;
export type GetSessionQueryResult = Apollo.QueryResult<GetSessionQuery, GetSessionQueryVariables>;
export type AccountKeySpecifier = ('amountOwing' | 'contacts' | 'description' | 'externalId' | 'id' | 'meta' | 'setupBreakdown' | 'standing' | 'status' | 'targetInstalments' | 'type' | AccountKeySpecifier)[];
export type AccountFieldPolicy = {
	amountOwing?: FieldPolicy<any> | FieldReadFunction<any>,
	contacts?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	externalId?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	meta?: FieldPolicy<any> | FieldReadFunction<any>,
	setupBreakdown?: FieldPolicy<any> | FieldReadFunction<any>,
	standing?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	targetInstalments?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AccountConnectionKeySpecifier = ('edges' | 'pageInfo' | 'total' | AccountConnectionKeySpecifier)[];
export type AccountConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	total?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AccountEdgeKeySpecifier = ('cursor' | 'node' | AccountEdgeKeySpecifier)[];
export type AccountEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AccountLookupSliceKeySpecifier = ('accountType' | 'description' | 'externalId' | AccountLookupSliceKeySpecifier)[];
export type AccountLookupSliceFieldPolicy = {
	accountType?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	externalId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AccountStandingKeySpecifier = ('instalmentPlanCancelledReason' | 'instalmentPlanId' | 'owner' | 'paymentId' | 'status' | AccountStandingKeySpecifier)[];
export type AccountStandingFieldPolicy = {
	instalmentPlanCancelledReason?: FieldPolicy<any> | FieldReadFunction<any>,
	instalmentPlanId?: FieldPolicy<any> | FieldReadFunction<any>,
	owner?: FieldPolicy<any> | FieldReadFunction<any>,
	paymentId?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ActionKeySpecifier = ('description' | 'id' | 'linkTo' | 'name' | 'timestamp' | 'type' | ActionKeySpecifier)[];
export type ActionFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	linkTo?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	timestamp?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ActionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'total' | ActionConnectionKeySpecifier)[];
export type ActionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	total?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ActionEdgeKeySpecifier = ('cursor' | 'node' | ActionEdgeKeySpecifier)[];
export type ActionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AddPaymentMethodOutputKeySpecifier = ('contact' | 'paymentMethodId' | AddPaymentMethodOutputKeySpecifier)[];
export type AddPaymentMethodOutputFieldPolicy = {
	contact?: FieldPolicy<any> | FieldReadFunction<any>,
	paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AudienceStatsKeySpecifier = ('activeInstalmentPlans' | 'activeInstalmentPlansLastMonth' | 'totalAccounts' | 'totalAccountsLastMonth' | 'totalContacts' | 'totalContactsLastMonth' | AudienceStatsKeySpecifier)[];
export type AudienceStatsFieldPolicy = {
	activeInstalmentPlans?: FieldPolicy<any> | FieldReadFunction<any>,
	activeInstalmentPlansLastMonth?: FieldPolicy<any> | FieldReadFunction<any>,
	totalAccounts?: FieldPolicy<any> | FieldReadFunction<any>,
	totalAccountsLastMonth?: FieldPolicy<any> | FieldReadFunction<any>,
	totalContacts?: FieldPolicy<any> | FieldReadFunction<any>,
	totalContactsLastMonth?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AuditLogKeySpecifier = ('action' | 'adminId' | 'adminName' | 'correlationId' | 'id' | 'ip' | 'isMutation' | 'isSuccess' | 'timestamp' | AuditLogKeySpecifier)[];
export type AuditLogFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>,
	adminId?: FieldPolicy<any> | FieldReadFunction<any>,
	adminName?: FieldPolicy<any> | FieldReadFunction<any>,
	correlationId?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	ip?: FieldPolicy<any> | FieldReadFunction<any>,
	isMutation?: FieldPolicy<any> | FieldReadFunction<any>,
	isSuccess?: FieldPolicy<any> | FieldReadFunction<any>,
	timestamp?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AuditLogConnectionKeySpecifier = ('edges' | 'pageInfo' | 'total' | AuditLogConnectionKeySpecifier)[];
export type AuditLogConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	total?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AuditLogEdgeKeySpecifier = ('cursor' | 'node' | AuditLogEdgeKeySpecifier)[];
export type AuditLogEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BankKeySpecifier = ('code' | 'name' | BankKeySpecifier)[];
export type BankFieldPolicy = {
	code?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactKeySpecifier = ('accounts' | 'createdAt' | 'email' | 'emailVerified' | 'familyName' | 'givenName' | 'id' | 'mobile' | 'mobileVerified' | 'paymentMethods' | 'status' | 'title' | 'updatedAt' | ContactKeySpecifier)[];
export type ContactFieldPolicy = {
	accounts?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	emailVerified?: FieldPolicy<any> | FieldReadFunction<any>,
	familyName?: FieldPolicy<any> | FieldReadFunction<any>,
	givenName?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	mobile?: FieldPolicy<any> | FieldReadFunction<any>,
	mobileVerified?: FieldPolicy<any> | FieldReadFunction<any>,
	paymentMethods?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactConnectionKeySpecifier = ('edges' | 'pageInfo' | 'total' | ContactConnectionKeySpecifier)[];
export type ContactConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	total?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactEdgeKeySpecifier = ('cursor' | 'node' | ContactEdgeKeySpecifier)[];
export type ContactEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FileKeySpecifier = ('createdAt' | 'fileFormat' | 'fileName' | 'fileType' | 'signedUrl' | 'status' | 'uploadedBy' | 'uploadedVia' | FileKeySpecifier)[];
export type FileFieldPolicy = {
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	fileFormat?: FieldPolicy<any> | FieldReadFunction<any>,
	fileName?: FieldPolicy<any> | FieldReadFunction<any>,
	fileType?: FieldPolicy<any> | FieldReadFunction<any>,
	signedUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	uploadedBy?: FieldPolicy<any> | FieldReadFunction<any>,
	uploadedVia?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GetSignedUrlOutputKeySpecifier = ('fields' | 'url' | GetSignedUrlOutputKeySpecifier)[];
export type GetSignedUrlOutputFieldPolicy = {
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	url?: FieldPolicy<any> | FieldReadFunction<any>
};
export type InstalmentKeySpecifier = ('amount' | 'cancelledAt' | 'dueAt' | 'instalmentId' | 'missedAt' | 'paidAt' | 'payments' | 'refundedAt' | 'skippedAt' | 'status' | InstalmentKeySpecifier)[];
export type InstalmentFieldPolicy = {
	amount?: FieldPolicy<any> | FieldReadFunction<any>,
	cancelledAt?: FieldPolicy<any> | FieldReadFunction<any>,
	dueAt?: FieldPolicy<any> | FieldReadFunction<any>,
	instalmentId?: FieldPolicy<any> | FieldReadFunction<any>,
	missedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	paidAt?: FieldPolicy<any> | FieldReadFunction<any>,
	payments?: FieldPolicy<any> | FieldReadFunction<any>,
	refundedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	skippedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>
};
export type InstalmentBreakdownKeySpecifier = ('amount' | 'label' | InstalmentBreakdownKeySpecifier)[];
export type InstalmentBreakdownFieldPolicy = {
	amount?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>
};
export type InstalmentPlanKeySpecifier = ('account' | 'accountId' | 'amount' | 'amountDue' | 'amountPaid' | 'billerId' | 'cancelledAt' | 'cancelledReason' | 'completedAt' | 'createdAt' | 'currency' | 'events' | 'frequency' | 'id' | 'instalments' | 'mode' | 'nextInstalmentDueAt' | 'ownerType' | 'paymentMethod' | 'paymentMethodId' | 'rebalance' | 'status' | 'testMode' | InstalmentPlanKeySpecifier)[];
export type InstalmentPlanFieldPolicy = {
	account?: FieldPolicy<any> | FieldReadFunction<any>,
	accountId?: FieldPolicy<any> | FieldReadFunction<any>,
	amount?: FieldPolicy<any> | FieldReadFunction<any>,
	amountDue?: FieldPolicy<any> | FieldReadFunction<any>,
	amountPaid?: FieldPolicy<any> | FieldReadFunction<any>,
	billerId?: FieldPolicy<any> | FieldReadFunction<any>,
	cancelledAt?: FieldPolicy<any> | FieldReadFunction<any>,
	cancelledReason?: FieldPolicy<any> | FieldReadFunction<any>,
	completedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	currency?: FieldPolicy<any> | FieldReadFunction<any>,
	events?: FieldPolicy<any> | FieldReadFunction<any>,
	frequency?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	instalments?: FieldPolicy<any> | FieldReadFunction<any>,
	mode?: FieldPolicy<any> | FieldReadFunction<any>,
	nextInstalmentDueAt?: FieldPolicy<any> | FieldReadFunction<any>,
	ownerType?: FieldPolicy<any> | FieldReadFunction<any>,
	paymentMethod?: FieldPolicy<any> | FieldReadFunction<any>,
	paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>,
	rebalance?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	testMode?: FieldPolicy<any> | FieldReadFunction<any>
};
export type InstalmentPlanEventKeySpecifier = ('createdAt' | 'data' | 'id' | 'type' | InstalmentPlanEventKeySpecifier)[];
export type InstalmentPlanEventFieldPolicy = {
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	data?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MutationKeySpecifier = ('addPaymentMethod' | 'archivePayer' | 'cancelInstalmentPlan' | 'createContact' | 'createInstalmentPlan' | 'createPayer' | 'getSignedUrl' | 'rebalancePlan' | 'reviewPlanRequest' | 'sendAdminRequestedNotification' | MutationKeySpecifier)[];
export type MutationFieldPolicy = {
	addPaymentMethod?: FieldPolicy<any> | FieldReadFunction<any>,
	archivePayer?: FieldPolicy<any> | FieldReadFunction<any>,
	cancelInstalmentPlan?: FieldPolicy<any> | FieldReadFunction<any>,
	createContact?: FieldPolicy<any> | FieldReadFunction<any>,
	createInstalmentPlan?: FieldPolicy<any> | FieldReadFunction<any>,
	createPayer?: FieldPolicy<any> | FieldReadFunction<any>,
	getSignedUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	rebalancePlan?: FieldPolicy<any> | FieldReadFunction<any>,
	reviewPlanRequest?: FieldPolicy<any> | FieldReadFunction<any>,
	sendAdminRequestedNotification?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NotificationKeySpecifier = ('channel' | 'id' | 'name' | 'preview' | 'sentAt' | 'status' | NotificationKeySpecifier)[];
export type NotificationFieldPolicy = {
	channel?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	preview?: FieldPolicy<any> | FieldReadFunction<any>,
	sentAt?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NotificationConnectionKeySpecifier = ('edges' | 'pageInfo' | 'total' | NotificationConnectionKeySpecifier)[];
export type NotificationConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	total?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NotificationEdgeKeySpecifier = ('cursor' | 'node' | NotificationEdgeKeySpecifier)[];
export type NotificationEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OwnerKeySpecifier = ('id' | 'type' | OwnerKeySpecifier)[];
export type OwnerFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageInfoKeySpecifier = ('endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | PageInfoKeySpecifier)[];
export type PageInfoFieldPolicy = {
	endCursor?: FieldPolicy<any> | FieldReadFunction<any>,
	hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPreviousPage?: FieldPolicy<any> | FieldReadFunction<any>,
	startCursor?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PayerKeySpecifier = ('account' | 'accountId' | 'createdAt' | 'deletedAt' | 'deletedReason' | 'id' | 'meta' | 'payerId' | 'paymentMethods' | 'updatedAt' | PayerKeySpecifier)[];
export type PayerFieldPolicy = {
	account?: FieldPolicy<any> | FieldReadFunction<any>,
	accountId?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	deletedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	deletedReason?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	meta?: FieldPolicy<any> | FieldReadFunction<any>,
	payerId?: FieldPolicy<any> | FieldReadFunction<any>,
	paymentMethods?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PayerConnectionKeySpecifier = ('edges' | 'pageInfo' | 'total' | PayerConnectionKeySpecifier)[];
export type PayerConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	total?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PayerEdgeKeySpecifier = ('cursor' | 'node' | PayerEdgeKeySpecifier)[];
export type PayerEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PaymentKeySpecifier = ('account' | 'accountId' | 'amount' | 'createdAt' | 'currency' | 'failedAt' | 'failedReason' | 'gateway' | 'gatewayTransactionId' | 'id' | 'instalmentId' | 'instalmentPlanId' | 'mode' | 'paymentMethod' | 'paymentMethodId' | 'processedAt' | 'refundStatus' | 'refundedAt' | 'status' | 'succeededAt' | 'testMode' | PaymentKeySpecifier)[];
export type PaymentFieldPolicy = {
	account?: FieldPolicy<any> | FieldReadFunction<any>,
	accountId?: FieldPolicy<any> | FieldReadFunction<any>,
	amount?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	currency?: FieldPolicy<any> | FieldReadFunction<any>,
	failedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	failedReason?: FieldPolicy<any> | FieldReadFunction<any>,
	gateway?: FieldPolicy<any> | FieldReadFunction<any>,
	gatewayTransactionId?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	instalmentId?: FieldPolicy<any> | FieldReadFunction<any>,
	instalmentPlanId?: FieldPolicy<any> | FieldReadFunction<any>,
	mode?: FieldPolicy<any> | FieldReadFunction<any>,
	paymentMethod?: FieldPolicy<any> | FieldReadFunction<any>,
	paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>,
	processedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	refundStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	refundedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	succeededAt?: FieldPolicy<any> | FieldReadFunction<any>,
	testMode?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PaymentConnectionKeySpecifier = ('edges' | 'pageInfo' | 'total' | PaymentConnectionKeySpecifier)[];
export type PaymentConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	total?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PaymentEdgeKeySpecifier = ('cursor' | 'node' | PaymentEdgeKeySpecifier)[];
export type PaymentEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PaymentMethodKeySpecifier = ('bank' | 'card' | 'id' | 'nzBank' | 'reason' | 'status' | 'type' | PaymentMethodKeySpecifier)[];
export type PaymentMethodFieldPolicy = {
	bank?: FieldPolicy<any> | FieldReadFunction<any>,
	card?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	nzBank?: FieldPolicy<any> | FieldReadFunction<any>,
	reason?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PaymentMethodBankKeySpecifier = ('accountName' | 'accountNumber' | 'bsb' | 'gateway' | 'name' | PaymentMethodBankKeySpecifier)[];
export type PaymentMethodBankFieldPolicy = {
	accountName?: FieldPolicy<any> | FieldReadFunction<any>,
	accountNumber?: FieldPolicy<any> | FieldReadFunction<any>,
	bsb?: FieldPolicy<any> | FieldReadFunction<any>,
	gateway?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PaymentMethodCardKeySpecifier = ('brand' | 'expiryMonth' | 'expiryYear' | 'gateway' | 'holderName' | 'last4' | PaymentMethodCardKeySpecifier)[];
export type PaymentMethodCardFieldPolicy = {
	brand?: FieldPolicy<any> | FieldReadFunction<any>,
	expiryMonth?: FieldPolicy<any> | FieldReadFunction<any>,
	expiryYear?: FieldPolicy<any> | FieldReadFunction<any>,
	gateway?: FieldPolicy<any> | FieldReadFunction<any>,
	holderName?: FieldPolicy<any> | FieldReadFunction<any>,
	last4?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PaymentMethodNZBankKeySpecifier = ('accountName' | 'accountNumber' | 'gateway' | 'name' | PaymentMethodNZBankKeySpecifier)[];
export type PaymentMethodNZBankFieldPolicy = {
	accountName?: FieldPolicy<any> | FieldReadFunction<any>,
	accountNumber?: FieldPolicy<any> | FieldReadFunction<any>,
	gateway?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PaymentStatsKeySpecifier = ('totalFailed' | 'totalFailedLastMonth' | 'totalScheduled' | 'totalScheduledLastMonth' | 'totalSuccessful' | 'totalSuccessfulLastMonth' | PaymentStatsKeySpecifier)[];
export type PaymentStatsFieldPolicy = {
	totalFailed?: FieldPolicy<any> | FieldReadFunction<any>,
	totalFailedLastMonth?: FieldPolicy<any> | FieldReadFunction<any>,
	totalScheduled?: FieldPolicy<any> | FieldReadFunction<any>,
	totalScheduledLastMonth?: FieldPolicy<any> | FieldReadFunction<any>,
	totalSuccessful?: FieldPolicy<any> | FieldReadFunction<any>,
	totalSuccessfulLastMonth?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PlanRebalanceStatusKeySpecifier = ('cause' | 'outcome' | PlanRebalanceStatusKeySpecifier)[];
export type PlanRebalanceStatusFieldPolicy = {
	cause?: FieldPolicy<any> | FieldReadFunction<any>,
	outcome?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PlanRequestKeySpecifier = ('acceptedAt' | 'accountId' | 'billerId' | 'contactId' | 'createdAt' | 'frequency' | 'id' | 'instalmentAmount' | 'paymentMethodId' | 'rejectedAt' | 'startAt' | 'updatedAt' | PlanRequestKeySpecifier)[];
export type PlanRequestFieldPolicy = {
	acceptedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	accountId?: FieldPolicy<any> | FieldReadFunction<any>,
	billerId?: FieldPolicy<any> | FieldReadFunction<any>,
	contactId?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	frequency?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	instalmentAmount?: FieldPolicy<any> | FieldReadFunction<any>,
	paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>,
	rejectedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	startAt?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PlanRequestConnectionKeySpecifier = ('edges' | 'pageInfo' | 'total' | PlanRequestConnectionKeySpecifier)[];
export type PlanRequestConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	total?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PlanRequestEdgeKeySpecifier = ('cursor' | 'node' | PlanRequestEdgeKeySpecifier)[];
export type PlanRequestEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryKeySpecifier = ('account' | 'accountLookup' | 'accountTypes' | 'accounts' | 'actions' | 'audienceStats' | 'auditLogs' | 'bankByCode' | 'contact' | 'contacts' | 'files' | 'instalmentPlan' | 'instalmentPlans' | 'notifications' | 'payer' | 'payers' | 'paymentStats' | 'payments' | 'planRequests' | 'scheduledPayments' | 'session' | QueryKeySpecifier)[];
export type QueryFieldPolicy = {
	account?: FieldPolicy<any> | FieldReadFunction<any>,
	accountLookup?: FieldPolicy<any> | FieldReadFunction<any>,
	accountTypes?: FieldPolicy<any> | FieldReadFunction<any>,
	accounts?: FieldPolicy<any> | FieldReadFunction<any>,
	actions?: FieldPolicy<any> | FieldReadFunction<any>,
	audienceStats?: FieldPolicy<any> | FieldReadFunction<any>,
	auditLogs?: FieldPolicy<any> | FieldReadFunction<any>,
	bankByCode?: FieldPolicy<any> | FieldReadFunction<any>,
	contact?: FieldPolicy<any> | FieldReadFunction<any>,
	contacts?: FieldPolicy<any> | FieldReadFunction<any>,
	files?: FieldPolicy<any> | FieldReadFunction<any>,
	instalmentPlan?: FieldPolicy<any> | FieldReadFunction<any>,
	instalmentPlans?: FieldPolicy<any> | FieldReadFunction<any>,
	notifications?: FieldPolicy<any> | FieldReadFunction<any>,
	payer?: FieldPolicy<any> | FieldReadFunction<any>,
	payers?: FieldPolicy<any> | FieldReadFunction<any>,
	paymentStats?: FieldPolicy<any> | FieldReadFunction<any>,
	payments?: FieldPolicy<any> | FieldReadFunction<any>,
	planRequests?: FieldPolicy<any> | FieldReadFunction<any>,
	scheduledPayments?: FieldPolicy<any> | FieldReadFunction<any>,
	session?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ReviewPlanRequestOutputKeySpecifier = ('instalmentPlan' | 'planRequest' | ReviewPlanRequestOutputKeySpecifier)[];
export type ReviewPlanRequestOutputFieldPolicy = {
	instalmentPlan?: FieldPolicy<any> | FieldReadFunction<any>,
	planRequest?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ScheduledPaymentKeySpecifier = ('account' | 'accountId' | 'amount' | 'currency' | 'dueAt' | 'id' | 'instalmentId' | 'instalmentPlanId' | 'paymentMethod' | 'paymentMethodId' | ScheduledPaymentKeySpecifier)[];
export type ScheduledPaymentFieldPolicy = {
	account?: FieldPolicy<any> | FieldReadFunction<any>,
	accountId?: FieldPolicy<any> | FieldReadFunction<any>,
	amount?: FieldPolicy<any> | FieldReadFunction<any>,
	currency?: FieldPolicy<any> | FieldReadFunction<any>,
	dueAt?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	instalmentId?: FieldPolicy<any> | FieldReadFunction<any>,
	instalmentPlanId?: FieldPolicy<any> | FieldReadFunction<any>,
	paymentMethod?: FieldPolicy<any> | FieldReadFunction<any>,
	paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ScheduledPaymentConnectionKeySpecifier = ('edges' | 'pageInfo' | 'total' | ScheduledPaymentConnectionKeySpecifier)[];
export type ScheduledPaymentConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	total?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ScheduledPaymentEdgeKeySpecifier = ('cursor' | 'node' | ScheduledPaymentEdgeKeySpecifier)[];
export type ScheduledPaymentEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SendAdminRequestedNotificationOutputKeySpecifier = ('body' | 'dryRun' | SendAdminRequestedNotificationOutputKeySpecifier)[];
export type SendAdminRequestedNotificationOutputFieldPolicy = {
	body?: FieldPolicy<any> | FieldReadFunction<any>,
	dryRun?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SessionKeySpecifier = ('adminId' | 'billerId' | 'billerName' | 'billerSlug' | 'email' | 'expiresAt' | SessionKeySpecifier)[];
export type SessionFieldPolicy = {
	adminId?: FieldPolicy<any> | FieldReadFunction<any>,
	billerId?: FieldPolicy<any> | FieldReadFunction<any>,
	billerName?: FieldPolicy<any> | FieldReadFunction<any>,
	billerSlug?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	expiresAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TargetInstalmentKeySpecifier = ('amount' | 'dueAt' | TargetInstalmentKeySpecifier)[];
export type TargetInstalmentFieldPolicy = {
	amount?: FieldPolicy<any> | FieldReadFunction<any>,
	dueAt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StrictTypedTypePolicies = {
	Account?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AccountKeySpecifier | (() => undefined | AccountKeySpecifier),
		fields?: AccountFieldPolicy,
	},
	AccountConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AccountConnectionKeySpecifier | (() => undefined | AccountConnectionKeySpecifier),
		fields?: AccountConnectionFieldPolicy,
	},
	AccountEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AccountEdgeKeySpecifier | (() => undefined | AccountEdgeKeySpecifier),
		fields?: AccountEdgeFieldPolicy,
	},
	AccountLookupSlice?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AccountLookupSliceKeySpecifier | (() => undefined | AccountLookupSliceKeySpecifier),
		fields?: AccountLookupSliceFieldPolicy,
	},
	AccountStanding?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AccountStandingKeySpecifier | (() => undefined | AccountStandingKeySpecifier),
		fields?: AccountStandingFieldPolicy,
	},
	Action?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ActionKeySpecifier | (() => undefined | ActionKeySpecifier),
		fields?: ActionFieldPolicy,
	},
	ActionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ActionConnectionKeySpecifier | (() => undefined | ActionConnectionKeySpecifier),
		fields?: ActionConnectionFieldPolicy,
	},
	ActionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ActionEdgeKeySpecifier | (() => undefined | ActionEdgeKeySpecifier),
		fields?: ActionEdgeFieldPolicy,
	},
	AddPaymentMethodOutput?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AddPaymentMethodOutputKeySpecifier | (() => undefined | AddPaymentMethodOutputKeySpecifier),
		fields?: AddPaymentMethodOutputFieldPolicy,
	},
	AudienceStats?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AudienceStatsKeySpecifier | (() => undefined | AudienceStatsKeySpecifier),
		fields?: AudienceStatsFieldPolicy,
	},
	AuditLog?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AuditLogKeySpecifier | (() => undefined | AuditLogKeySpecifier),
		fields?: AuditLogFieldPolicy,
	},
	AuditLogConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AuditLogConnectionKeySpecifier | (() => undefined | AuditLogConnectionKeySpecifier),
		fields?: AuditLogConnectionFieldPolicy,
	},
	AuditLogEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AuditLogEdgeKeySpecifier | (() => undefined | AuditLogEdgeKeySpecifier),
		fields?: AuditLogEdgeFieldPolicy,
	},
	Bank?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BankKeySpecifier | (() => undefined | BankKeySpecifier),
		fields?: BankFieldPolicy,
	},
	Contact?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactKeySpecifier | (() => undefined | ContactKeySpecifier),
		fields?: ContactFieldPolicy,
	},
	ContactConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactConnectionKeySpecifier | (() => undefined | ContactConnectionKeySpecifier),
		fields?: ContactConnectionFieldPolicy,
	},
	ContactEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactEdgeKeySpecifier | (() => undefined | ContactEdgeKeySpecifier),
		fields?: ContactEdgeFieldPolicy,
	},
	File?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FileKeySpecifier | (() => undefined | FileKeySpecifier),
		fields?: FileFieldPolicy,
	},
	GetSignedUrlOutput?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GetSignedUrlOutputKeySpecifier | (() => undefined | GetSignedUrlOutputKeySpecifier),
		fields?: GetSignedUrlOutputFieldPolicy,
	},
	Instalment?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InstalmentKeySpecifier | (() => undefined | InstalmentKeySpecifier),
		fields?: InstalmentFieldPolicy,
	},
	InstalmentBreakdown?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InstalmentBreakdownKeySpecifier | (() => undefined | InstalmentBreakdownKeySpecifier),
		fields?: InstalmentBreakdownFieldPolicy,
	},
	InstalmentPlan?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InstalmentPlanKeySpecifier | (() => undefined | InstalmentPlanKeySpecifier),
		fields?: InstalmentPlanFieldPolicy,
	},
	InstalmentPlanEvent?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InstalmentPlanEventKeySpecifier | (() => undefined | InstalmentPlanEventKeySpecifier),
		fields?: InstalmentPlanEventFieldPolicy,
	},
	Mutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier),
		fields?: MutationFieldPolicy,
	},
	Notification?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NotificationKeySpecifier | (() => undefined | NotificationKeySpecifier),
		fields?: NotificationFieldPolicy,
	},
	NotificationConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NotificationConnectionKeySpecifier | (() => undefined | NotificationConnectionKeySpecifier),
		fields?: NotificationConnectionFieldPolicy,
	},
	NotificationEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NotificationEdgeKeySpecifier | (() => undefined | NotificationEdgeKeySpecifier),
		fields?: NotificationEdgeFieldPolicy,
	},
	Owner?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OwnerKeySpecifier | (() => undefined | OwnerKeySpecifier),
		fields?: OwnerFieldPolicy,
	},
	PageInfo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageInfoKeySpecifier | (() => undefined | PageInfoKeySpecifier),
		fields?: PageInfoFieldPolicy,
	},
	Payer?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PayerKeySpecifier | (() => undefined | PayerKeySpecifier),
		fields?: PayerFieldPolicy,
	},
	PayerConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PayerConnectionKeySpecifier | (() => undefined | PayerConnectionKeySpecifier),
		fields?: PayerConnectionFieldPolicy,
	},
	PayerEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PayerEdgeKeySpecifier | (() => undefined | PayerEdgeKeySpecifier),
		fields?: PayerEdgeFieldPolicy,
	},
	Payment?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaymentKeySpecifier | (() => undefined | PaymentKeySpecifier),
		fields?: PaymentFieldPolicy,
	},
	PaymentConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaymentConnectionKeySpecifier | (() => undefined | PaymentConnectionKeySpecifier),
		fields?: PaymentConnectionFieldPolicy,
	},
	PaymentEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaymentEdgeKeySpecifier | (() => undefined | PaymentEdgeKeySpecifier),
		fields?: PaymentEdgeFieldPolicy,
	},
	PaymentMethod?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaymentMethodKeySpecifier | (() => undefined | PaymentMethodKeySpecifier),
		fields?: PaymentMethodFieldPolicy,
	},
	PaymentMethodBank?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaymentMethodBankKeySpecifier | (() => undefined | PaymentMethodBankKeySpecifier),
		fields?: PaymentMethodBankFieldPolicy,
	},
	PaymentMethodCard?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaymentMethodCardKeySpecifier | (() => undefined | PaymentMethodCardKeySpecifier),
		fields?: PaymentMethodCardFieldPolicy,
	},
	PaymentMethodNZBank?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaymentMethodNZBankKeySpecifier | (() => undefined | PaymentMethodNZBankKeySpecifier),
		fields?: PaymentMethodNZBankFieldPolicy,
	},
	PaymentStats?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaymentStatsKeySpecifier | (() => undefined | PaymentStatsKeySpecifier),
		fields?: PaymentStatsFieldPolicy,
	},
	PlanRebalanceStatus?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PlanRebalanceStatusKeySpecifier | (() => undefined | PlanRebalanceStatusKeySpecifier),
		fields?: PlanRebalanceStatusFieldPolicy,
	},
	PlanRequest?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PlanRequestKeySpecifier | (() => undefined | PlanRequestKeySpecifier),
		fields?: PlanRequestFieldPolicy,
	},
	PlanRequestConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PlanRequestConnectionKeySpecifier | (() => undefined | PlanRequestConnectionKeySpecifier),
		fields?: PlanRequestConnectionFieldPolicy,
	},
	PlanRequestEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PlanRequestEdgeKeySpecifier | (() => undefined | PlanRequestEdgeKeySpecifier),
		fields?: PlanRequestEdgeFieldPolicy,
	},
	Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier),
		fields?: QueryFieldPolicy,
	},
	ReviewPlanRequestOutput?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ReviewPlanRequestOutputKeySpecifier | (() => undefined | ReviewPlanRequestOutputKeySpecifier),
		fields?: ReviewPlanRequestOutputFieldPolicy,
	},
	ScheduledPayment?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ScheduledPaymentKeySpecifier | (() => undefined | ScheduledPaymentKeySpecifier),
		fields?: ScheduledPaymentFieldPolicy,
	},
	ScheduledPaymentConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ScheduledPaymentConnectionKeySpecifier | (() => undefined | ScheduledPaymentConnectionKeySpecifier),
		fields?: ScheduledPaymentConnectionFieldPolicy,
	},
	ScheduledPaymentEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ScheduledPaymentEdgeKeySpecifier | (() => undefined | ScheduledPaymentEdgeKeySpecifier),
		fields?: ScheduledPaymentEdgeFieldPolicy,
	},
	SendAdminRequestedNotificationOutput?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SendAdminRequestedNotificationOutputKeySpecifier | (() => undefined | SendAdminRequestedNotificationOutputKeySpecifier),
		fields?: SendAdminRequestedNotificationOutputFieldPolicy,
	},
	Session?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SessionKeySpecifier | (() => undefined | SessionKeySpecifier),
		fields?: SessionFieldPolicy,
	},
	TargetInstalment?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TargetInstalmentKeySpecifier | (() => undefined | TargetInstalmentKeySpecifier),
		fields?: TargetInstalmentFieldPolicy,
	}
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;

import {AbsoluteDatePolicy} from 'payble-graphql-apollo-scalars';

export const scalarTypePolicies = {
  Instalment: { fields: {dueAt: AbsoluteDatePolicy, nextInstalmentDueAt: AbsoluteDatePolicy, startAt: AbsoluteDatePolicy } },
  InstalmentPlan: { fields: {dueAt: AbsoluteDatePolicy, nextInstalmentDueAt: AbsoluteDatePolicy, startAt: AbsoluteDatePolicy } },
  PlanRequest: { fields: {dueAt: AbsoluteDatePolicy, nextInstalmentDueAt: AbsoluteDatePolicy, startAt: AbsoluteDatePolicy } },
  ScheduledPayment: { fields: {dueAt: AbsoluteDatePolicy, nextInstalmentDueAt: AbsoluteDatePolicy, startAt: AbsoluteDatePolicy } },
  TargetInstalment: { fields: {dueAt: AbsoluteDatePolicy, nextInstalmentDueAt: AbsoluteDatePolicy, startAt: AbsoluteDatePolicy } },
}