import {z} from 'zod';
import {autoImportConfigSchema, okayResponse} from '../schemas';
import {defineHTTPEndpoint} from '../utils';
import {autoImportsFormConfig} from 'payble-shared';

export const saveAutoImportConfig = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'POST /saveAutoImportConfig',
  requestSchema: autoImportsFormConfig.validation,
  responseSchema: okayResponse,
});

export const getAutoImportConfig = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'GET /getAutoImportConfig',
  queryKey: r => ['getAutoImportConfig', r?.autoImportConfigId],
  requestSchema: z.object({
    autoImportConfigId: z.string(),
  }),
  responseSchema: autoImportConfigSchema,
});

export const getAllAutoImportConfigs = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'GET /getAllAutoImportConfigs',
  queryKey: () => ['getAllAutoImportConfigs'],
  requestSchema: z.object({}),
  responseSchema: z.array(autoImportConfigSchema),
});

export const archiveAutoImportConfig = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'DELETE /archiveAutoImportConfig',
  requestSchema: z.object({
    autoImportConfigId: z.string(),
  }),
  responseSchema: okayResponse,
});
