import {
  FormConfig,
  FormConfigField,
  ParsedFormConfigField,
  safeEvaluate,
} from 'payble-shared';
import {Form} from './Form';

type FieldTypes = {
  [K in ParsedFormConfigField['type']]: (
    field: Extract<ParsedFormConfigField, {type: K}> & {
      context: {
        formFieldFactory: FormFieldFactory;
      };
    }
  ) => JSX.Element;
};

const FIELD_TYPES: FieldTypes = {
  text: field => (
    <Form.Input
      type="text"
      name={field.name}
      label={field.label}
      placeholder={field.placeholder}
    />
  ),
  password: field => (
    <Form.Input
      type="password"
      name={field.name}
      label={field.label}
      placeholder={field.placeholder}
    />
  ),
  toggle: field => <Form.Switch name={field.name} label={field.label} />,
  number: field => (
    <Form.Input
      type="number"
      name={field.name}
      label={field.label}
      placeholder={field.placeholder}
    />
  ),
  date: field => (
    <Form.Input
      type="date"
      name={field.name}
      label={field.label}
      placeholder={field.placeholder}
    />
  ),
  textarea: field => (
    <Form.Textarea
      name={field.name}
      label={field.label}
      placeholder={field.placeholder}
    />
  ),
  fieldset: field => {
    return (
      <fieldset>
        <legend className="text-lg font-bold">{field.label}</legend>
        <div className="flex flex-col gap-4 p-4 mt-2 border rounded-sm">
          {field.fields.length ? (
            field.fields.map(f =>
              field.context.formFieldFactory.createField({
                ...f,
                name: `${field.name}.${f.name}`,
              })
            )
          ) : (
            <p>N/A</p>
          )}
        </div>
      </fieldset>
    );
  },
  dropdown: field => (
    <Form.Select
      name={field.name}
      label={field.label}
      options={field.options}
      aria-placeholder={field.placeholder}
    />
  ),
  radioGroup: field => (
    <Form.RadioGroup
      name={field.name}
      label={field.label}
      options={field.options}
      aria-placeholder={field.placeholder}
    />
  ),
};

const addShared = (rendered: JSX.Element, field: FormConfigField) => {
  return (
    <div key={field.name}>
      {rendered}
      {field.description && (
        <p className="text-sm text-gray-500">{field.description}</p>
      )}
    </div>
  );
};

export class FormFieldFactory {
  constructor(private context?: FormConfig['context']) {}

  private parseFieldConfig(
    field: FormConfigField,
    values: Record<string, any>
  ) {
    const context = {
      ...values,
      context: this.context,
    };

    const parsedField = {
      ...field,
    } as ParsedFormConfigField;

    if (field.if) {
      parsedField.if = !!safeEvaluate(field.if, context);
    }

    if (
      parsedField.type === 'dropdown' &&
      typeof parsedField.options === 'string'
    ) {
      const options = safeEvaluate(parsedField.options, context) ?? [];
      if (options) {
        if (!Array.isArray(options)) {
          throw new Error('Dropdown options must be an array');
        }
        parsedField.options = options;
      }
    }

    if (
      parsedField.type === 'fieldset' &&
      typeof parsedField.fields === 'string'
    ) {
      const fields = safeEvaluate(parsedField.fields, context) ?? [];
      if (fields) {
        if (!Array.isArray(fields)) {
          throw new Error('Fieldset fields must be an array');
        }
        parsedField.fields = fields;
      }
    }

    if ('options' in parsedField) {
      parsedField.options = parsedField.options.map(option => ({
        ...option,
        label: option.label ?? option.value,
      }));
    }

    return {
      ...parsedField,
      context: {
        formFieldFactory: this,
      },
    };
  }

  createField(field: FormConfigField) {
    const FieldComponent = FIELD_TYPES[field.type];

    if (!FieldComponent) {
      throw new Error(`Field type ${field.type} not found`);
    }

    return (
      <Form.Connect key={field.name}>
        {values => {
          const parsedConfig = this.parseFieldConfig(field, values);
          const shouldShow = parsedConfig.if ?? true;
          return shouldShow
            ? addShared(FieldComponent(parsedConfig as never), field)
            : null;
        }}
      </Form.Connect>
    );
  }
}
