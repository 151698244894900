import React from 'react';
import {Account, AccountEdge} from 'lib/graphql/API';
import {AccountTable} from '../components/AccountTable';

type ContactAccountsProps = {
  accounts?: Account[];
};

export const ContactAccounts: React.FC<ContactAccountsProps> = ({accounts}) => {
  if (!accounts) {
    return <></>;
  }

  const edges = accounts.map(x => {
    return {
      cursor: '',
      __typename: 'AccountEdge',
      node: x,
    };
  }) as AccountEdge[];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <AccountTable loading={false} error={undefined} accounts={edges} />
    </div>
  );
};
