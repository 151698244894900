import React, {useEffect, useState} from 'react';

import {Formik} from 'formik';
import {
  AdminRequestedNotificationInput,
  AdminRequestedNotificationInputType,
  useGetAccountsSearchLazyQuery,
  useSendAdminRequestedNotificationMutation,
} from 'lib/graphql/API';
import {PopOverSidebar} from 'components/organisms/PopOverSidebar';
import {FormHeader} from './components/FormHeader';
import {FormSubmission} from './components/FormSubmission';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {SelectMode} from './components/SelectMode';
import {SmsPreview} from './components/SmsPreview';
import {Search} from 'components/atoms/Search';
import {useDebouncedCallback} from 'use-debounce';
import {formatSearchResult} from './utils/formatSearchResult';

const modes = [
  {
    name: 'Update Payment Method',
    id: 'updatePaymentMethod' as const,
    description: 'Send a notification with link to update payment method',
  },
  {
    name: 'Send Link To Create Plan',
    id: 'createPlan' as const,
    description: 'Send a notification with link to create a plan',
  },
  {
    name: 'Send Link to make payment',
    id: 'oneOffPayment' as const,
    description: 'Send a notification with link to make a payment',
  },
];

type Form = {
  mode?: AdminRequestedNotificationInputType;
  accountId: string;
  type: string;
};

const FORM_INITIAL_VALUES: Form = {
  mode: undefined,
  accountId: '',
  type: '',
};

type SendNotificationFormProps = {
  contactId: string;
  disclosure: ReturnType<typeof useDisclosure>;
};

export const SendNotificationForm: React.FC<SendNotificationFormProps> = ({
  contactId,
  disclosure: {isOpen, onClose},
}) => {
  const [body, setBody] = useState<string[]>();
  const [sendNotification] = useSendAdminRequestedNotificationMutation();

  useEffect(() => {
    if (isOpen) {
      setBody([]);
    }
  }, [isOpen]);

  const [accountFetch, {data: accountData, loading: accountLoading}] =
    useGetAccountsSearchLazyQuery({variables: {offset: 0}});

  const searchAccounts = useDebouncedCallback(value => {
    accountFetch({
      variables: {
        search: value,
        first: 10,
      },
    });
  }, 500);

  function previewSms(input: Omit<AdminRequestedNotificationInput, 'dryRun'>) {
    setBody([]);
    sendNotification({
      variables: {
        input: {
          ...input,
          dryRun: true,
        },
      },
    }).then(result => {
      const body = result.data?.sendAdminRequestedNotification?.body;

      if (body) {
        setBody(body);
      }
    });
  }

  return (
    <PopOverSidebar
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <Formik
        initialValues={FORM_INITIAL_VALUES}
        onSubmit={async (values, {setSubmitting}) => {
          if (!values.mode || !body) {
            return;
          }
          setSubmitting(true);

          await sendNotification({
            variables: {
              input: {
                contactId,
                type: values.mode,
                dryRun: false,
                accountId: values.accountId,
              },
            },
          });

          setSubmitting(false);

          onClose();
        }}
      >
        {({values, handleSubmit, setFieldValue, isSubmitting}) => (
          <form
            className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
            onSubmit={handleSubmit}
          >
            <div className="flex-1">
              <FormHeader
                setOpen={onClose}
                title="Send Notification"
                description="Send a notification to your contact."
              />
              <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                  <SelectMode
                    selectedMode={modes.find(mode => mode.id === values.mode)}
                    modes={modes}
                    setMode={mode => {
                      setFieldValue('mode', mode.id);
                      setFieldValue('accountId', '');

                      previewSms({
                        contactId,
                        type: mode.id as AdminRequestedNotificationInputType,
                        accountId: values.accountId,
                      });
                    }}
                  />
                </div>
              </div>
              {values.mode &&
                ['createPlan', 'oneOffPayment'].includes(values.mode) && (
                  <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                    <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <div>
                        <label
                          htmlFor="link-account"
                          className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >
                          Account
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <Search
                          onSearchInputChange={searchValue => {
                            searchAccounts(searchValue);
                          }}
                          onSearchSelected={selected => {
                            setFieldValue('accountId', selected.id);
                            setFieldValue('type', selected.type);

                            if (!values.mode) {
                              return;
                            }

                            previewSms({
                              contactId,
                              type: values.mode,
                              accountId: selected.id,
                            });
                          }}
                          selectedValue={{
                            id: values.accountId,
                            type: values.type,
                          }}
                          loading={accountLoading}
                          values={formatSearchResult(accountData)}
                        />
                      </div>
                    </div>
                  </div>
                )}
              <SmsPreview
                messages={
                  body?.length
                    ? [
                        {
                          key: contactId,
                          text: body,
                          sent: false,
                        },
                      ]
                    : []
                }
              />
            </div>

            <FormSubmission
              isSubmitting={isSubmitting || !body?.length}
              onCancel={onClose}
              submissionButtonText="Send Notification"
            />
          </form>
        )}
      </Formik>
    </PopOverSidebar>
  );
};
