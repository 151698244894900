import {z} from 'zod';
import {zAbsoluteDate} from './generic';

export const autoImportConfigSchema = z.object({
  autoImportConfigId: z.string(),
  billerId: z.string(),
  processType: z.string(),
  filenameMatcher: z.string(),
  strategy: z.string(),
  defaults: z.object({}).catchall(z.any()).nullable(),
  createdAt: zAbsoluteDate,
  updatedAt: zAbsoluteDate,
});
