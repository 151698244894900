import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  UseFormProps,
  UseFormReturn,
  useForm,
} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {FormInput} from './FormInput';
import {FormFieldError} from './FormFieldError';
import {FormInputPhone} from './FormInputPhone';
import {HTMLAttributes, useEffect} from 'react';
import {FormInputOTP} from './FormInputOTP';
import {z} from 'zod';
import {FormSubmitButton} from './FormSubmitButton';
import {cva} from '../lib/utils';
import {FormConnect} from './FormConnect';
import {FormErrorMessage} from './FormErrorMessage';
import {FormRadioGroup} from './FormRadioGroup';
import {FormInputNumberFormat} from './FormInputNumberFormat';
import {FormSelect} from './FormSelect';
import {FormField} from './FormField';
import {FormSwitch} from './FormSwitch';
import {FormTextarea} from './FormTextarea';
import {useDebouncedCallback} from 'use-debounce';
import {FormInputDate} from './FormInputDate';

const formVariants = cva('flex flex-col gap-5');

export type FormRef<T extends FieldValues> = UseFormReturn<T>;

export type FormProps<T extends FieldValues = FieldValues> = Omit<
  HTMLAttributes<HTMLFormElement>,
  'onSubmit'
> & {
  defaultValues?: UseFormProps<T>['defaultValues'];
  mode?: UseFormProps<T>['mode'];
  autoSubmit?: boolean;
  reValidateMode?: UseFormProps<T>['reValidateMode'];
  onSubmit?: (data: T) => void;
  errors?: UseFormProps<T>['errors'];
  children: React.ReactNode;
  formRef?: React.MutableRefObject<FormRef<T> | undefined>;
  schema?: z.ZodType<any, z.ZodTypeDef, any>;
};

export const Form = <TFieldValues extends FieldValues = FieldValues>({
  defaultValues,
  mode,
  reValidateMode,
  onSubmit,
  autoSubmit,
  children,
  errors,
  schema,
  formRef,
  ...props
}: FormProps<TFieldValues>) => {
  const methods = useForm({
    defaultValues,
    mode,
    reValidateMode,
    errors,
    shouldUnregister: true,
    resolver: schema ? zodResolver(schema) : undefined,
  });
  const formErrors = methods.formState.errors;

  useEffect(() => {
    const nonFieldErrors = Object.entries(methods.formState.errors)
      .filter(([key]) => !Object.keys(methods.getValues()).includes(key))
      .map(([key, e]) => `${key}: ${e?.message}`);

    if (nonFieldErrors.length) {
      throw new Error(
        `Found errors for fields that weren't found in the form:- ${nonFieldErrors.join(
          ','
        )}`
      );
    }
  }, [formErrors]);

  const submit: SubmitHandler<TFieldValues> = data => onSubmit?.(data);
  const onAutoSubmit = useDebouncedCallback(async e => {
    await methods.handleSubmit(submit)(e);
  }, 500);
  const {watch} = methods;

  useEffect(() => {
    const watcher = autoSubmit
      ? watch(async data => {
          await onAutoSubmit(data);
        })
      : null;

    return () => {
      watcher?.unsubscribe();
    };
  }, [watch, onAutoSubmit]);

  useEffect(() => {
    if (formRef) {
      formRef.current = methods;
    }
  }, [formRef, methods]);

  return (
    <FormProvider {...methods}>
      <form
        {...props}
        className={formVariants({className: props.className})}
        onSubmit={async e => {
          e.stopPropagation();
          await methods.handleSubmit(submit)(e);
        }}
      >
        {children}
      </form>

      <FormErrorMessage message={errors?.root?.server?.message} />
    </FormProvider>
  );
};

Form.Field = FormField;
Form.Select = FormSelect;
Form.Switch = FormSwitch;
Form.Input = FormInput;
Form.InputPhone = FormInputPhone;
Form.InputOTP = FormInputOTP;
Form.InputNumberFormat = FormInputNumberFormat;
Form.RadioGroup = FormRadioGroup;
Form.Textarea = FormTextarea;
Form.InputDate = FormInputDate;
Form.SubmitButton = FormSubmitButton;
Form.Connect = FormConnect;
Form.FieldError = FormFieldError;
Form.ErrorMessage = FormErrorMessage;
